import React from "react";
// import { Helmet } from "react-helmet";
import { Helmet, HelmetProvider } from "react-helmet-async";
import AboutUs from "./AboutUs/AboutUs";
import Achievements from "./Achievements/Achievements";
import Intro from "./Intro/Intro";
import Vision from "./Vision/Vision";

const About = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>
          {" "}
          About Us| Best quality software Development Company in India
        </title>
        <meta
          name="description"
          content="BindassDeal is a best quality software development company based out of India providing innovative and cost-effective mobile application development and customization services."
        />
      </Helmet>
      <Intro />
      <Achievements />
      <Vision />
    </HelmetProvider>
  );
};

export default About;
