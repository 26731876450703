import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import classes from "./News.module.css";

const News = () => {
  const news = useSelector((state) => state.content.news);

  return (
    <section className={classes.news}>
      <div className={classes.container}>
        <div className={classes.heading}>
          <h3>latest news</h3>
        </div>
        <div className={classes.newsArticles}>
          <ul className={classes.newsArticleList}>
            {news.map((news, i) => {
              const { id, category, title, desc } = news;

              return (
                <li key={id} className={classes.newsArticleListItem}>
                  <Link to="">
                    <div className={classes.newsImg}>
                      <img
                        src={require(`../../../../resources/image/news-img-${id}.png`)}
                        alt="news"
                      />
                    </div>
                    <div className={classes.newsArticle}>
                      <p className={classes.newsCategory}>- {category}</p>
                      <h4 className={classes.newsTitle}>{title}</h4>
                      <p className={classes.newsDesc}>{desc}</p>
                    </div>
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default News;
