import React from "react";
// import { Helmet } from "react-helmet";
import { Helmet, HelmetProvider } from "react-helmet-async";
import classes from "./EcommerceApplicationServices.module.css";

const EcommerceApplicationServices = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Ecommerce Application Services - BindassDeal Pvt. Ltd.</title>
        <meta
          name="description"
          content="We specialize in developing ecommerce applications that help businesses of all sizes sell their products and services online."
        />
      </Helmet>
      <section className={classes.hero}>
        <div className={classes.title}>
          <div className={classes.container}>
            <h5 className={classes.pageName}>Ecommerce Application</h5>
            {/* <h3 className={classes.headingTitle}>
        <span>Bindassdeal</span> Private Limited
      </h3> */}
            <p className={classes.headingDesc}>
              {/* At Bindassdeal Private Limited, we */}
              We specialize in developing ecommerce applications that help
              businesses of all sizes sell their products and services online.
              {/* Our team of experienced software developers, designers, and project managers can deliver
              ecommerce solutions that are tailored to your unique business
              needs, whether you require a new ecommerce platform, an update to
              an existing system, or integration with other systems. */}
            </p>
          </div>
        </div>
      </section>
      <section className={classes.about}>
        <div className={classes.container}>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1487014679447-9f8336841d58?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1605&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Custom Ecommerce Development</h3>
              <p>
                We can develop custom ecommerce solutions that are tailored to
                your business needs, ensuring that your ecommerce platform is
                efficient, scalable, and user-friendly.
              </p>
            </div>
          </div>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1461749280684-dccba630e2f6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1469&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Ecommerce Platform Integration</h3>
              <p>
                We can integrate your ecommerce platform with other systems,
                such as inventory management, customer relationship management
                (CRM), or enterprise resource planning (ERP) systems, ensuring
                that your ecommerce solutions work seamlessly with your existing
                infrastructure.
              </p>
            </div>
          </div>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1563013544-824ae1b704d3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Payment Gateway Integration</h3>
              <p>
                We can integrate your ecommerce platform with payment gateway
                providers, such as PayPal, Stripe, or Square, ensuring that your
                customers can securely and conveniently make online purchases.
              </p>
            </div>
          </div>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1616077167599-cad3639f9cbd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Ecommerce Mobile Application Development</h3>
              <p>
                We can develop mobile applications that help you reach your
                customers on the go, enabling them to make purchases through iOS
                or Android platforms.
              </p>
            </div>
          </div>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1542744095-291d1f67b221?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Ecommerce Website Development</h3>
              <p>
                We can develop ecommerce websites that provide a seamless
                shopping experience for your customers, with intuitive
                navigation, user-friendly interfaces, and secure checkout
                processes.
              </p>
            </div>
          </div>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1516321318423-f06f85e504b3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Ecommerce Maintenance and Support</h3>
              <p>
                Our team can provide ongoing maintenance and support services,
                ensuring that your ecommerce solutions continue to meet your
                business needs and remain up-to-date with the latest technology
                trends.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className={classes.summary}>
        <div className={classes.container}>
          <div className={classes.summaryText}>
            <p>
              At Bindassdeal Private Limited, we are committed to delivering
              ecommerce solutions that help our clients achieve their business
              goals.
              {/* Contact us today to learn more about how we can help you
              sell your products and services online and grow your business. */}
            </p>
          </div>
        </div>
      </section>
    </HelmetProvider>
  );
};

export default EcommerceApplicationServices;

/*

Ecommerce Application Services

At Bindassdeal Private Limited, we specialize in developing ecommerce applications that help businesses of all sizes sell their products and services online. Our team of experienced software developers, designers, and project managers can deliver ecommerce solutions that are tailored to your unique business needs, whether you require a new ecommerce platform, an update to an existing system, or integration with other systems.
Our ecommerce application services include:
-Custom Ecommerce Development: We can develop custom ecommerce solutions that are tailored to your business needs, ensuring that your ecommerce platform is efficient, scalable, and user-friendly.
-Ecommerce Platform Integration: We can integrate your ecommerce platform with other systems, such as inventory management, customer relationship management (CRM), or enterprise resource planning (ERP) systems, ensuring that your ecommerce solutions work seamlessly with your existing infrastructure.
-Payment Gateway Integration: We can integrate your ecommerce platform with payment gateway providers, such as PayPal, Stripe, or Square, ensuring that your customers can securely and conveniently make online purchases.
-Ecommerce Mobile Application Development: We can develop mobile applications that help you reach your customers on the go, enabling them to make purchases through iOS or Android platforms.
-Ecommerce Website Development: We can develop ecommerce websites that provide a seamless shopping experience for your customers, with intuitive navigation, user-friendly interfaces, and secure checkout processes.
-Ecommerce Maintenance and Support: Our team can provide ongoing maintenance and support services, ensuring that your ecommerce solutions continue to meet your business needs and remain up-to-date with the latest technology trends.
At Bindassdeal Private Limited, we are committed to delivering ecommerce solutions that help our clients achieve their business goals. Contact us today to learn more about how we can help you sell your products and services online and grow your business.

*/
