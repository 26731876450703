import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import MobileNav from "./MobileNav";
import DesktopNav from "./DesktopNav";

import classes from "./Navigation.module.css";

// prettier-ignore
const NAV_LINKS = [
  { id: 1, name: "Services", menu: [{id: 11, link: "Data Services", page: "/"}, {id: 12, link: "Crypto & Blockchain Services", page: "/"}, {id: 13, link: "Software Development", page: "/"}, {id: 14, link: "Mobile Application", page: "/"}, {id: 15, link: "E-commerce Application", page: "/"}, {id: 16, link: "Website Development", page: "/"}, {id: 17, link: "Social Media Marketing", page: "/"}, {id: 18, link: "Designs & Graphics", page: "/"}, {id: 19, link: "Resource & Hiring", page: "/"}] },
  { id: 2, name: "Solutions", menu: [{id: 22, link: "Gaming", page: "/"}, {id: 23, link: "Banking", page: "/"}, {id: 25, link: "eCommerce", page: "/"}, {id: 26, link: "Advertising", page: "/"}, {id: 27, link: "Media & Entertainment", page: "/"}] },
  { id: 3, name: "Hiring & Resourcing", menu: [{id: 31, link: "General Staffing", page: "/"}, {id: 32, link: "Outsource Servicing", page: "/"}, {id: 33, link: "Hire, Train & Deploy", page: "/"}, {id: 34, link: "Permanent Placements", page: "/"}, {id: 36, link: "Payroll & Compliance Resourcing", page: "/"}, {id: 37, link: "Enterprise Solution", page: "/"}, {id: 38, link: "Training & Skilling", page: "/"}] },
  { id: 4, name: "Insights", menu: [{id: 41, link: "Insight of the Company", page: "/"}] },
  { id: 5, name: "Contact us", menu: [] },
];

const Navigation = () => {
  const navLinks = useSelector((state) => state.content.navLinks);
  const isNavFixed = useSelector((state) => state.content.isNavFixed);
  const [navigationLinks, setNavigationLinks] = useState([]);

  useEffect(() => {
    const tempArr = navLinks.map((el, i) => ({ ...el, isSelected: false }));
    setNavigationLinks(tempArr);
  }, []);

  return (
    <>
      <header className={classes.header}>
        <div className={classes.container}>
          <Link to="/" className={classes.logo__link}>
            <div className={classes.logo}>
              <img
                src={require("../../../resources/image/logo.png")}
                alt="logo"
                className={classes.logoImg}
              />
            </div>
          </Link>
          <MobileNav navigationLinks={navigationLinks} />
          <DesktopNav navigationLinks={navigationLinks} />
        </div>
      </header>
    </>
  );
};

export default Navigation;
