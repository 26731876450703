import React from "react";
// import { Helmet } from "react-helmet";
import { Helmet, HelmetProvider } from "react-helmet-async";
import classes from "./FintechSolutions.module.css";

const FintechSolutions = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>
          Mobile Banking and Payment Apps in Los Angeles | Best Fintech
          Solutions Services Gurugram | Payment Gateway Service Provider in
          Singapore
        </title>
        <meta
          name="description"
          content="Bindassdeal offers mobile banking and payment apps development services. With their knowledge of the latest trends in banking and payment technology, they can help you launch an app that is secure and reliable."
        />
      </Helmet>
      <section className={classes.hero}>
        <div className={classes.title}>
          <div className={classes.container}>
            <h5 className={classes.pageName}>Fintech</h5>
            {/* <h3 className={classes.headingTitle}>
        <span>Bindassdeal</span> Private Limited
      </h3> */}
            <p className={classes.headingDesc}>
              {/* At Bindassdeal Private Limited, we */}
              We offer FinTech solutions that help financial institutions and
              businesses of all sizes optimize their operations, reduce costs,
              and improve customer experience.
              {/* Our team of experienced FinTech experts, software engineers, and
              designers can deliver solutions that are tailored to your unique
              business needs, whether you need to develop a mobile banking app,
              implement a payment gateway, or automate your accounting and
              reporting processes. */}
            </p>
          </div>
        </div>
      </section>
      <section className={classes.about}>
        <div className={classes.container}>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1533234944761-2f5337579079?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Mobile Banking and Payment Apps</h3>
              <p>
                We can help you develop secure, user-friendly mobile banking and
                payment apps that enable your customers to manage their
                accounts, make payments, and access financial services
                on-the-go.
              </p>
            </div>
          </div>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1599050751795-6cdaafbc2319?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1528&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Payment Gateways</h3>
              <p>
                We can help you implement payment gateways that enable you to
                process online transactions, accept various payment methods, and
                ensure secure payments.
              </p>
            </div>
          </div>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1519995451813-39e29e054914?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Blockchain and Cryptocurrency</h3>
              <p>
                We can help you leverage blockchain and cryptocurrency
                technologies to develop innovative financial solutions, such as
                digital wallets, smart contracts, and decentralized finance
                (DeFi) applications.
              </p>
            </div>
          </div>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1563212034-a3c52118cce2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Accounting and Reporting Automation</h3>
              <p>
                We can help you automate your accounting and reporting
                processes, enabling you to save time and reduce errors, while
                ensuring compliance with regulatory requirements.
              </p>
            </div>
          </div>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1603985529862-9e12198c9a60?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1461&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Risk Management and Compliance</h3>
              <p>
                We can help you implement risk management and compliance
                solutions that enable you to identify and mitigate risks, comply
                with regulatory requirements, and safeguard your reputation.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className={classes.summary}>
        <div className={classes.container}>
          <div className={classes.summaryText}>
            <p>
              At Bindassdeal Private Limited, we are committed to delivering
              FinTech solutions that help our clients stay ahead of the
              competition and meet the evolving needs of their customers.
              {/* Contact us today to learn more about how we can help you leverage
              FinTech to drive growth and innovation. */}
            </p>
          </div>
        </div>
      </section>
    </HelmetProvider>
  );
};

export default FintechSolutions;

/*

FinTech Solutions

At Bindassdeal Private Limited, we offer FinTech solutions that help financial institutions and businesses of all sizes optimize their operations, reduce costs, and improve customer experience. Our team of experienced FinTech experts, software engineers, and designers can deliver solutions that are tailored to your unique business needs, whether you need to develop a mobile banking app, implement a payment gateway, or automate your accounting and reporting processes.
Our FinTech solutions include:
-Mobile Banking and Payment Apps: We can help you develop secure, user-friendly mobile banking and payment apps that enable your customers to manage their accounts, make payments, and access financial services on-the-go.
-Payment Gateways: We can help you implement payment gateways that enable you to process online transactions, accept various payment methods, and ensure secure payments.
-Blockchain and Cryptocurrency: We can help you leverage blockchain and cryptocurrency technologies to develop innovative financial solutions, such as digital wallets, smart contracts, and decentralized finance (DeFi) applications.
-Accounting and Reporting Automation: We can help you automate your accounting and reporting processes, enabling you to save time and reduce errors, while ensuring compliance with regulatory requirements.
-Risk Management and Compliance: We can help you implement risk management and compliance solutions that enable you to identify and mitigate risks, comply with regulatory requirements, and safeguard your reputation.
At Bindassdeal Private Limited, we are committed to delivering FinTech solutions that help our clients stay ahead of the competition and meet the evolving needs of their customers. Contact us today to learn more about how we can help you leverage FinTech to drive growth and innovation.

*/
