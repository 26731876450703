import React from "react";
// import { Helmet } from "react-helmet";
import { Helmet, HelmetProvider } from "react-helmet-async";
import classes from "./DesignGraphicServices.module.css";

const DesignGraphicServices = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>
          Website Designer in Los Angeles | Top Web Designers in Singapore | Los
          Angeles Web Design Company
        </title>
        <meta
          name="description"
          content="Our website designers in los Angeles specialize in creating dynamic and visually pleasing websites that are sure to help create a vibrant online presence."
        />
      </Helmet>
      <section className={classes.hero}>
        <div className={classes.title}>
          <div className={classes.container}>
            <h5 className={classes.pageName}>Design and Graphic</h5>
            {/* <h3 className={classes.headingTitle}>
        <span>Bindassdeal</span> Private Limited
      </h3> */}
            <p className={classes.headingDesc}>
              {/* At Bindassdeal Private Limited, we */}
              We offer design and graphic services that help businesses of all
              sizes create visually appealing and engaging materials that
              effectively communicate their brand message.
              {/* Our team of experienced designers and graphic
              artists can deliver solutions that are tailored to your unique
              business needs, whether you require a new brand identity, website
              design, or marketing collateral. */}
            </p>
          </div>
        </div>
      </section>
      <section className={classes.about}>
        <div className={classes.container}>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1611241893603-3c359704e0ee?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Brand Identity Design</h3>
              <p>
                We can create a new brand identity for your business that
                accurately reflects your brand values, mission, and vision,
                ensuring that your brand stands out in a crowded marketplace.
              </p>
            </div>
          </div>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1498050108023-c5249f4df085?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1472&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Website Design</h3>
              <p>
                We can design a website that is aesthetically pleasing,
                user-friendly, and responsive, ensuring that your website
                effectively communicates your brand message and engages with
                your target audience.
              </p>
            </div>
          </div>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1587440871875-191322ee64b0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>UX/UI Design</h3>
              <p>
                We can design user-friendly and intuitive user interfaces and
                experiences that enhance the user experience and drive
                engagement and loyalty.
              </p>
            </div>
          </div>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1522204538344-922f76ecc041?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Marketing Collateral Design</h3>
              <p>
                We can create marketing materials that effectively communicate
                your brand message and drive customer engagement, such as
                brochures, flyers, business cards, and social media graphics.
              </p>
            </div>
          </div>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1627440765112-680c1633c4ae?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Packaging Design</h3>
              <p>
                We can create packaging designs that help your products stand
                out on the shelf and effectively communicate their unique
                selling points.
              </p>
            </div>
          </div>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1513542789411-b6a5d4f31634?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Illustration and Animation</h3>
              <p>
                We can create high-quality illustrations and animations that
                effectively communicate complex ideas and concepts in a visually
                appealing and engaging way.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className={classes.summary}>
        <div className={classes.container}>
          <div className={classes.summaryText}>
            <p>
              At Bindassdeal Private Limited, we are committed to delivering
              design and graphic solutions that help our clients achieve their
              business goals.
              {/* Contact us today to learn more about how we can
              help you create visually appealing and engaging materials that
              effectively communicate your brand message. */}
            </p>
          </div>
        </div>
      </section>
    </HelmetProvider>
  );
};

export default DesignGraphicServices;

/*

Design and Graphic Services

At Bindassdeal Private Limited, we offer design and graphic services that help businesses of all sizes create visually appealing and engaging materials that effectively communicate their brand message. Our team of experienced designers and graphic artists can deliver solutions that are tailored to your unique business needs, whether you require a new brand identity, website design, or marketing collateral.
Our design and graphic services include:
-Brand Identity Design: We can create a new brand identity for your business that accurately reflects your brand values, mission, and vision, ensuring that your brand stands out in a crowded marketplace.
-Website Design: We can design a website that is aesthetically pleasing, user-friendly, and responsive, ensuring that your website effectively communicates your brand message and engages with your target audience.
-UX/UI Design: We can design user-friendly and intuitive user interfaces and experiences that enhance the user experience and drive engagement and loyalty.
-Marketing Collateral Design: We can create marketing materials that effectively communicate your brand message and drive customer engagement, such as brochures, flyers, business cards, and social media graphics.
-Packaging Design: We can create packaging designs that help your products stand out on the shelf and effectively communicate their unique selling points.
-Illustration and Animation: We can create high-quality illustrations and animations that effectively communicate complex ideas and concepts in a visually appealing and engaging way.
At Bindassdeal Private Limited, we are committed to delivering design and graphic solutions that help our clients achieve their business goals. Contact us today to learn more about how we can help you create visually appealing and engaging materials that effectively communicate your brand message.

*/
