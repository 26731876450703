import React from "react";
// import { Helmet } from "react-helmet";
import { Helmet, HelmetProvider } from "react-helmet-async";
import classes from "./OutsourcingServices.module.css";

const OutsourcingServices = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>
          IT Project Outsourcing Services in Singapore | IT Project Outsourcing
          in India | staff outsourcing services in Chicago
        </title>
        <meta
          name="description"
          content="If you are looking for IT project outsourcing services in Singapore, then you can easily find a reliable partner to help with your project."
        />
      </Helmet>
      <section className={classes.hero}>
        <div className={classes.title}>
          <div className={classes.container}>
            <h5 className={classes.pageName}>Outsourcing</h5>
            {/* <h3 className={classes.headingTitle}>
        <span>Bindassdeal</span> Private Limited
      </h3> */}
            <p className={classes.headingDesc}>
              {/* At Bindassdeal Private Limited, we */}
              We understand that managing your own IT projects and resources can
              be complex, time-consuming, and costly.
              {/* That's why we offer comprehensive outsourcing solutions
              that help our clients manage their IT projects and resources more
              efficiently, while minimizing their costs and risks. */}
            </p>
          </div>
        </div>
      </section>
      <section className={classes.about}>
        <div className={classes.container}>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1563986768609-322da13575f3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Project Outsourcing</h3>
              <p>
                We can provide project outsourcing services to our clients,
                helping them to complete IT projects on time and within budget.
                Our team of experts has extensive experience in managing and
                executing IT projects, and we use proven methodologies and best
                practices to ensure project success.
              </p>
            </div>
          </div>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1531538606174-0f90ff5dce83?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Staff Outsourcing</h3>
              <p>
                We can provide staff outsourcing services to our clients,
                allowing them to supplement their existing workforce with
                additional resources on a short-term or long-term basis. Our
                staff outsourcing services can help our clients to meet their
                project deadlines and requirements, without incurring the costs
                of hiring and training additional permanent employees.
              </p>
            </div>
          </div>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1531973576160-7125cd663d86?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Managed Services</h3>
              <p>
                We can provide managed services to our clients, helping them to
                manage their IT infrastructure and applications more
                efficiently. Our managed services include monitoring,
                maintenance, and support for hardware, software, and networks,
                ensuring that our clients' IT systems are always up and running.
              </p>
            </div>
          </div>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1431540015161-0bf868a2d407?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Offshore Development</h3>
              <p>
                We can provide offshore development services to our clients,
                helping them to leverage the benefits of outsourcing to offshore
                locations. Our offshore development services can help our
                clients to reduce their costs, access a larger pool of skilled
                resources, and accelerate their time-to-market.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className={classes.summary}>
        <div className={classes.container}>
          <div className={classes.summaryText}>
            <p>
              At Bindassdeal Private Limited, we are committed to delivering
              outsourcing solutions that help our clients manage their IT
              projects and resources more efficiently, while minimizing their
              costs and risks.
              {/* Contact us today to learn more about how we can
              help you streamline your IT operations and achieve your business
              objectives. */}
            </p>
          </div>
        </div>
      </section>
    </HelmetProvider>
  );
};

export default OutsourcingServices;

/*

Outsourcing services

At Bindassdeal Private Limited, we understand that managing your own IT projects and resources can be complex, time-consuming, and costly. That's why we offer comprehensive outsourcing solutions that help our clients manage their IT projects and resources more efficiently, while minimizing their costs and risks.
Our outsourcing solutions include:
-Project Outsourcing: We can provide project outsourcing services to our clients, helping them to complete IT projects on time and within budget. Our team of experts has extensive experience in managing and executing IT projects, and we use proven methodologies and best practices to ensure project success.
-Staff Outsourcing: We can provide staff outsourcing services to our clients, allowing them to supplement their existing workforce with additional resources on a short-term or long-term basis. Our staff outsourcing services can help our clients to meet their project deadlines and requirements, without incurring the costs of hiring and training additional permanent employees.
-Managed Services: We can provide managed services to our clients, helping them to manage their IT infrastructure and applications more efficiently. Our managed services include monitoring, maintenance, and support for hardware, software, and networks, ensuring that our clients' IT systems are always up and running.
-Offshore Development: We can provide offshore development services to our clients, helping them to leverage the benefits of outsourcing to offshore locations. Our offshore development services can help our clients to reduce their costs, access a larger pool of skilled resources, and accelerate their time-to-market.
At Bindassdeal Private Limited, we are committed to delivering outsourcing solutions that help our clients manage their IT projects and resources more efficiently, while minimizing their costs and risks. Contact us today to learn more about how we can help you streamline your IT operations and achieve your business objectives.

*/
