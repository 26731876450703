import React from "react";

import classes from "./Hero.module.css";

const Hero = () => {
  return (
    <section className={classes.hero}>
      <div className={classes.container}>
        <div className={classes.heroImg}></div>
        <div className={classes.heroTxt}>
          <div style={{}} className={classes.heroTxtContent}>
            <h1>
              Future AI<br></br>& Blockchain
            </h1>
            <h2>Development Services</h2>
            <p>
              With data on over 8 million and thousands websites, get the
              complete picture of the mobile landscape you need to acquire.
            </p>
            <button type="button" onClick={() => {}}>
              Get Started
            </button>
          </div>
        </div>
      </div>
      <img
        src={require("../../../../resources/image/fill-hex.png")}
        alt="filled-hexagon"
        className={classes.filledHexagon}
      />
      <img
        src={require("../../../../resources/image/empty-hex.png")}
        alt="hollow-hexagon"
        className={classes.hollowHexagon}
      />
    </section>
  );
};

export default Hero;

/*

<div className={classes.hexagons}>
  <div style={{ position: "relative" }}>
    <img
      src={require("../../../resources/image/fill-hex.png")}
      alt="filled-hexagon"
      className={classes.filledHexagon}
    />
    <img
      src={require("../../../resources/image/empty-hex.png")}
      alt="hollow-hexagon"
      className={classes.hollowHexagon}
    />
  </div>
</div>

*/
