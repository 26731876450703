import { createSlice } from "@reduxjs/toolkit";
import { configureStore } from "@reduxjs/toolkit";

import {
  NAV_LINKS,
  DUMMY_SERVICES,
  DUMMY_NEWS,
  DUMMY_FOOTER_DATA
} from "./constants";

const initialState = {
  isNavFixed: false,
  navLinks: NAV_LINKS,
  services: DUMMY_SERVICES,
  news: DUMMY_NEWS,
  footerLinks: DUMMY_FOOTER_DATA
};

const contentSlice = createSlice({
  name: "content",
  initialState: initialState,
  reducers: {
    loadContent(state) {},
    navFix(state, action) {
      state.isNavFixed = action.payload;
    }
  }
});

const store = configureStore({
  reducer: { content: contentSlice.reducer }
});

export const contentActions = contentSlice.actions;

export default store;
