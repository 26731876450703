import React from "react";
// import { Helmet } from "react-helmet";
import { Helmet, HelmetProvider } from "react-helmet-async";
import classes from "./GeneralStaffingSolutions.module.css";

const GeneralStaffingSolutions = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>
          Los Angeles Staffing Agency | Contract Staffing Services in Mumbai |
          Contract Staffing Services Service Provider from Mumbai
        </title>
        <meta
          name="description"
          content="Contract staffing services in Mumbai are also a best option if you need to hire a specialized employee for a short project."
        />
      </Helmet>
      <section className={classes.hero}>
        <div className={classes.title}>
          <div className={classes.container}>
            <h5 className={classes.pageName}>General Staffing</h5>
            {/* <h3 className={classes.headingTitle}>
        <span>Bindassdeal</span> Private Limited
      </h3> */}
            <p className={classes.headingDesc}>
              {/* At Bindassdeal Private Limited, we */}
              We understand that finding the right talent is critical to the
              success of any organization.
              {/* That's why we offer comprehensive staffing solutions that help our
              clients find the right people for their teams, quickly and
              efficiently. */}
            </p>
          </div>
        </div>
      </section>
      <section className={classes.about}>
        <div className={classes.container}>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1484&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Permanent Staffing</h3>
              <p>
                We can help our clients find the right candidates for permanent
                positions within their organization. Our team of recruiters has
                access to a large network of qualified candidates, and we use
                advanced screening and selection techniques to ensure that we
                identify the best candidates for each position.
              </p>
            </div>
          </div>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1504384308090-c894fdcc538d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Contract Staffing</h3>
              <p>
                We can provide contract staffing services to our clients,
                helping them to quickly fill short-term or project-based
                positions. Our contract staffing services allow our clients to
                scale up or down their workforce based on their needs, without
                incurring the costs of hiring and training permanent employees.
              </p>
            </div>
          </div>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1542744173-8e7e53415bb0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Contract-to-Hire Staffing</h3>
              <p>
                We can also provide contract-to-hire staffing services, allowing
                our clients to evaluate candidates on a temporary basis before
                making a permanent hiring decision. This approach can help our
                clients minimize the risk of hiring the wrong candidate and
                ensure that they are making the right long-term hiring
                decisions.
              </p>
            </div>
          </div>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1598946114934-8cf259aa241d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Staff Augmentation</h3>
              <p>
                We can provide staff augmentation services to our clients,
                allowing them to supplement their existing workforce with
                additional resources on a short-term or long-term basis. Our
                staff augmentation services can help our clients to complete
                projects on time and within budget, without incurring the costs
                of hiring and training additional permanent employees.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className={classes.summary}>
        <div className={classes.container}>
          <div className={classes.summaryText}>
            <p>
              At Bindassdeal Private Limited, we are committed to delivering
              staffing solutions that help our clients find the right talent for
              their teams, quickly and efficiently.
              {/* Contact us today to learn
              more about how we can help you build the team you need to achieve
              your business objectives. */}
            </p>
          </div>
        </div>
      </section>
    </HelmetProvider>
  );
};

export default GeneralStaffingSolutions;

/*

General Staffing Solutions

At Bindassdeal Private Limited, we understand that finding the right talent is critical to the success of any organization. That's why we offer comprehensive staffing solutions that help our clients find the right people for their teams, quickly and efficiently.
Our staffing solutions include:
-Permanent Staffing: We can help our clients find the right candidates for permanent positions within their organization. Our team of recruiters has access to a large network of qualified candidates, and we use advanced screening and selection techniques to ensure that we identify the best candidates for each position.
-Contract Staffing: We can provide contract staffing services to our clients, helping them to quickly fill short-term or project-based positions. Our contract staffing services allow our clients to scale up or down their workforce based on their needs, without incurring the costs of hiring and training permanent employees.
-Contract-to-Hire Staffing: We can also provide contract-to-hire staffing services, allowing our clients to evaluate candidates on a temporary basis before making a permanent hiring decision. This approach can help our clients minimize the risk of hiring the wrong candidate and ensure that they are making the right long-term hiring decisions.
-Staff Augmentation: We can provide staff augmentation services to our clients, allowing them to supplement their existing workforce with additional resources on a short-term or long-term basis. Our staff augmentation services can help our clients to complete projects on time and within budget, without incurring the costs of hiring and training additional permanent employees.
At Bindassdeal Private Limited, we are committed to delivering staffing solutions that help our clients find the right talent for their teams, quickly and efficiently. Contact us today to learn more about how we can help you build the team you need to achieve your business objectives.

*/
