import React from "react";
// import { Helmet } from "react-helmet";
import { Helmet, HelmetProvider } from "react-helmet-async";
import classes from "./HireTrainDeploy.module.css";

const HireTrainDeploy = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>
          Recruitment Services in Mumbai | Placement Consultants in Mumbai |
          Best Recruitment Agency in India
        </title>
        <meta
          name="description"
          content="Mumbai, on the other hand, is home to some of the best recruitment services in the world. Bindassdeal looking to hire the best talent can use the services of these agencies to get the desired talent."
        />
      </Helmet>
      <section className={classes.hero}>
        <div className={classes.title}>
          <div className={classes.container}>
            <h5 className={classes.pageName}>Hire, Train & Deploy</h5>
            {/* <h3 className={classes.headingTitle}>
        <span>Bindassdeal</span> Private Limited
      </h3> */}
            <p className={classes.headingDesc}>
              {/* At Bindassdeal Private Limited, we */}
              We understand that finding and retaining skilled IT professionals
              can be a challenge for many organizations.
              {/* That's why we offer a comprehensive "Hire, Train,
              and Deploy" solution that helps our clients overcome these
              challenges and build a strong IT team. */}
            </p>
          </div>
        </div>
      </section>
      <section className={classes.about}>
        <div className={classes.container}>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1507679799987-c73779587ccf?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Recruitment Services</h3>
              <p>
                We can provide recruitment services to our clients, helping them
                to find the right candidates for their IT roles. Our recruitment
                services include job postings, candidate screening, and
                interview scheduling, ensuring that our clients have access to a
                pool of qualified candidates.
              </p>
            </div>
          </div>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1606857521015-7f9fcf423740?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Staff Outsourcing</h3>
              <p>
                We can provide training services to our clients, helping them to
                upskill their existing IT staff and prepare them for new roles
                and responsibilities. Our training services include customized
                training programs, on-the-job training, and certification
                courses, ensuring that our clients' IT staff have the skills and
                knowledge they need to succeed.
              </p>
            </div>
          </div>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1531973576160-7125cd663d86?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Deployment Services</h3>
              <p>
                We can provide deployment services to our clients, helping them
                to deploy their IT staff to their projects and assignments. Our
                deployment services include onboarding, project management, and
                performance tracking, ensuring that our clients' IT staff are
                productive and effective from day one.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className={classes.summary}>
        <div className={classes.container}>
          <div className={classes.summaryText}>
            <p>
              By leveraging our "Hire, Train, and Deploy" solution, our clients
              can build a strong and agile IT team, without incurring the costs
              and risks of hiring and training new staff.
              {/* Contact us today to
              learn more about how we can help you build a high-performing IT
              team that drives business success. */}
            </p>
          </div>
        </div>
      </section>
    </HelmetProvider>
  );
};

export default HireTrainDeploy;

/*

Hire, Train, and Deploy

At Bindassdeal Private Limited, we understand that finding and retaining skilled IT professionals can be a challenge for many organizations. That's why we offer a comprehensive "Hire, Train, and Deploy" solution that helps our clients overcome these challenges and build a strong IT team.
Our "Hire, Train, and Deploy" solution includes:
-Recruitment Services: We can provide recruitment services to our clients, helping them to find the right candidates for their IT roles. Our recruitment services include job postings, candidate screening, and interview scheduling, ensuring that our clients have access to a pool of qualified candidates.
-Training Services: We can provide training services to our clients, helping them to upskill their existing IT staff and prepare them for new roles and responsibilities. Our training services include customized training programs, on-the-job training, and certification courses, ensuring that our clients' IT staff have the skills and knowledge they need to succeed.
-Deployment Services: We can provide deployment services to our clients, helping them to deploy their IT staff to their projects and assignments. Our deployment services include onboarding, project management, and performance tracking, ensuring that our clients' IT staff are productive and effective from day one.
By leveraging our "Hire, Train, and Deploy" solution, our clients can build a strong and agile IT team, without incurring the costs and risks of hiring and training new staff. Contact us today to learn more about how we can help you build a high-performing IT team that drives business success.

*/
