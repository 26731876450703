import React from "react";
import { useSelector } from "react-redux";
import classes from "./Services.module.css";

const Services = () => {
  const services = useSelector((state) => state.content.services);

  return (
    <section className={classes.section}>
      <div className={classes.container}>
        <div className={classes.heading}>
          <h3>Services we offer</h3>
          <p>
            With data on over 8 million apps and thousands of websites, get the
            complete picture of the mobile landcape you need to acquire.
          </p>
        </div>
        <div className={classes.services}>
          <ul className={classes.servicesList}>
            {services.map((el, i) => {
              const { id, name, img } = el;
              return (
                <li key={id} className={classes.servicesListItem}>
                  {/* <span className={classes.liImg}> */}
                  <img
                    src={require("../../../../resources/image/brain-check-times.png")}
                    // src={require(img)}
                    // src={img}
                    alt="img"
                    className={""}
                  />
                  {/* </span> */}
                  <h4>{name}</h4>
                  <p>
                    Augment AI with business intelligence (BI) and analytics
                    tools that help you make well-rounded and accurate decisions
                  </p>
                </li>
              );
            })}
          </ul>
        </div>
        <img
          src={require("../../../../resources/image/bubbles-big.png")}
          alt="bubbles-big-svg"
          className={classes.bubblesBigSvg}
        />
      </div>
    </section>
  );
};

export default Services;
