import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import ReactDOM from "react-dom";
import { useNavigate } from "react-router-dom";

import classes from "./MobileNav.module.css";

// prettier-ignore
const NestedMenu = ({ menu, setIsNavOpen, navigationLinks, setNavigationLinks, closeAll }) => {
  const navigate = useNavigate();

  const toggleHandler = (item) => {
    // prettier-ignore
    // closeAll();
    setNavigationLinks((prev) => prev.map((el) => ({ ...el, isSelected: false }))); 
    setIsNavOpen(false);
    navigate(`${item.page}`);
    console.log(navigationLinks);
  };
  
  // prettier-ignore
  return (
    <div className={classes.nestedMenu}>
      <ul>
        {menu.map((item, i) => { return ( <li key={item.id} onClick={() => toggleHandler(item)}>{item.link}</li> ); })}
      </ul>
    </div>
  );
};

// prettier-ignore
const NavigationLink = ({ id, name, menu, isSelected, navigationLinks, setNavigationLinks, setIsNavOpen }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  
  const toggleHandler = (id) => {
    // prettier-ignore
    const tempArr = navigationLinks.map((el) => ({ ...el, isSelected: el.id === id ? !el.isSelected : false }));
    setNavigationLinks(tempArr);
  };
  
  // prettier-ignore
  // const closeAll = () => { setNavigationLinks((prev) => prev.map((el) => ({ ...el, isSelected: false }))); }
  const closeAll = () => { }

  return (
    // prettier-ignore
    <li className={classes.menuListItem} onClick={() => toggleHandler(id) // setIsMenuOpen((prev) => !prev)
      }>
      <div>
        <span>{name}</span>
        <i className={`fa fa-angle-${isSelected ? 'up' : 'down'}`} aria-hidden="true"></i>
      </div>
      {isSelected && (
        <NestedMenu menu={menu} setIsNavOpen={setIsNavOpen} navigationLinks={navigationLinks} setNavigationLinks={setNavigationLinks} closeAll={closeAll} />
      )}
    </li>
  );
};

const MobileNav = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const navLinks = useSelector((state) => state.content.navLinks);
  const [navigationLinks, setNavigationLinks] = useState([]);

  useEffect(() => {
    const tempArr = navLinks.map((el, i) => ({ ...el, isSelected: false }));
    setNavigationLinks(tempArr);
  }, []);

  const toggleHandler = () => {
    const tempArr = navigationLinks.map((el) => ({ ...el, isSelected: false }));
    setNavigationLinks(tempArr);
    console.log(tempArr);
    setIsNavOpen((prev) => !prev);
  };

  return (
    <>
      {ReactDOM.createPortal(
        // prettier-ignore
        isNavOpen && ( 
          <Overlay setIsNavOpen={setIsNavOpen} navigationLinks={navigationLinks} setNavigationLinks={setNavigationLinks} />
        ),
        document.getElementById("overlay")
      )}
      <nav className={classes.mobileNav}>
        <div
          className={`${
            isNavOpen
              ? `${classes.hamburger} ${classes.active}`
              : `${classes.hamburger}`
          }`}
          onClick={() => toggleHandler()}
        >
          <div className={classes["hamburger__line"]}>
            <div className={classes["hamburger__line--top"]}></div>
            <div className={classes["hamburger__line--middle"]}></div>
            <div className={classes["hamburger__line--bottom"]}></div>
          </div>
        </div>
        <div
          className={`${
            isNavOpen
              ? `${classes.navMenu} ${classes.active}`
              : `${classes.navMenu}`
          }`}
        >
          <div className={classes.menu}>
            <ul className={classes.menuList}>
              {navigationLinks.map((link, i) => {
                const { id, name, menu, isSelected } = link;
                return (
                  // prettier-ignore
                  <NavigationLink key={id} id={id} name={name} menu={menu} isSelected={isSelected} navigationLinks={navigationLinks} setNavigationLinks={setNavigationLinks} setIsNavOpen={setIsNavOpen} />
                );
              })}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default MobileNav;

const Overlay = ({ setIsNavOpen, navigationLinks, setNavigationLinks }) => {
  const toggleHandler = () => {
    const tempArr = navigationLinks.map((el) => ({ ...el, isSelected: false }));
    setNavigationLinks(tempArr);
    console.log(tempArr);
    setIsNavOpen(false);
  };

  return (
    <div onClick={() => toggleHandler()} className={classes.backdrop}></div>
  );
};
