import React from "react";
// import { useState } from "react";
import classes from "./Vision.module.css";

const Vision = () => {
  return (
    <section className={classes.vision}>
      <div className={classes.about}>
        <div className={classes.container}>
          <div className={classes.visionDesc}>
            <div className={classes.visionImg}>
              <img
                src="https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80"
                alt="img"
              />
            </div>
            <div className={classes.visionText}>
              <h3>Our Vision</h3>
              <p>
                Our vision at Bindassdeal Private Limited is to be a leader in
                the software industry, driving innovation and creating
                technology solutions that transform businesses and improve
                people's lives.
                <br />
                <br />
                We aim to be recognized as a trusted partner to businesses of
                all sizes, providing exceptional software solutions that help
                them achieve their business goals and thrive in the digital age.
                <br />
                <br />
                We believe in continuous learning and improvement, and we are
                dedicated to staying ahead of the curve by embracing the latest
                technologies and development methodologies.
                <br />
                <br />
                Ultimately, our vision is to create a better world through
                technology, one solution at a time. We are excited about the
                possibilities, and we look forward to partnering with businesses
                and individuals who share our vision for a brighter future.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Vision;

/*

<div className={classes.container}>
  <h3>Vision</h3>
  { <p>{aboutCompany}</p> }
  <p>
    Our vision at Bindassdeal Private Limited is to be a leader in the
    software industry, driving innovation and creating technology
    solutions that transform businesses and improve people's lives.
    <br />
    { We believe that technology has the power to solve some of the world's
    most pressing challenges, and we are committed to leveraging our
    expertise and passion for innovation to make a positive impact.
    <br /> }
    We aim to be recognized as a trusted partner to businesses of all
    sizes, providing exceptional software solutions that help them achieve
    their business goals and thrive in the digital age.
    <br />
    { Our vision is to foster a culture of innovation and collaboration,
    where our team of talented software developers, designers, and project
    managers work together to push the boundaries of what's possible.
    <br /> }
    We believe in continuous learning and improvement, and we are
    dedicated to staying ahead of the curve by embracing the latest
    technologies and development methodologies.
    <br />
    Ultimately, our vision is to create a better world through technology,
    one solution at a time. We are excited about the possibilities, and we
    look forward to partnering with businesses and individuals who share
    our vision for a brighter future.
  </p>
</div>

*/
