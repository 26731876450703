import React from "react";
// import { Helmet } from "react-helmet";
import { Helmet, HelmetProvider } from "react-helmet-async";
import classes from "./AdvertisingSolutions.module.css";

const AdvertisingSolutions = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>
          Display Advertising Agency in Los Angeles | Display Advertising Agency
          in Singapore | Search Engine Advertising in Chicago
        </title>
        <meta
          name="description"
          content="Display advertising agency in Los Angeles helps businesses manage their online presence. These agencies provide a platform for businesses to create and manage their online campaigns."
        />
      </Helmet>
      <section className={classes.hero}>
        <div className={classes.title}>
          <div className={classes.container}>
            <h5 className={classes.pageName}>Advertising</h5>
            {/* <h3 className={classes.headingTitle}>
        <span>Bindassdeal</span> Private Limited
      </h3> */}
            <p className={classes.headingDesc}>
              {/* At Bindassdeal Private Limited, we */}
              We offer a wide range of advertising solutions that help
              businesses of all sizes reach their target audience and achieve
              their marketing goals.
              {/* Our team of experts can develop customized advertising campaigns that are
              tailored to your business needs and provide maximum return on
              investment. */}
            </p>
          </div>
        </div>
      </section>
      <section className={classes.about}>
        <div className={classes.container}>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1557858310-9052820906f7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Display Advertising</h3>
              <p>
                We can create visually appealing ads that are displayed on
                relevant websites and social media platforms. Our team can help
                you target specific audiences based on their demographics,
                interests, and browsing behavior, and ensure that your ads are
                displayed to the right people at the right time.
              </p>
            </div>
          </div>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1662052955098-042b46e60c2b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1462&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Search Engine Advertising</h3>
              <p>
                We can help you design games that are fun, engaging, and
                immersive. Our team can create game mechanics, game worlds, and
                characters that resonate with your target audience and elevate
                your game to the next level.
              </p>
            </div>
          </div>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1643503640904-75c1a2093570?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Social Media Advertising</h3>
              <p>
                We can create ads that are displayed on social media platforms
                such as Facebook, Instagram, and Twitter. Our team can help you
                target specific audiences based on their location, interests,
                and behavior, and ensure that your ads are displayed to the
                people most likely to engage with your brand.
              </p>
            </div>
          </div>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1612117229486-78abff6d84c3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Video Advertising</h3>
              <p>
                We can create video ads that are displayed on YouTube and other
                video-sharing platforms. Our team can help you create engaging
                videos that showcase your products or services, and target
                specific audiences based on their viewing behavior.
              </p>
            </div>
          </div>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1460925895917-afdab827c52f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1115&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Programmatic Advertising</h3>
              <p>
                We can help you automate your advertising campaigns using
                programmatic technology. Our team can help you optimize your
                campaigns in real-time, ensuring that your ads are displayed to
                the right people at the right time, and that your budget is
                being used effectively.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className={classes.summary}>
        <div className={classes.container}>
          <div className={classes.summaryText}>
            <p>
              At Bindassdeal Private Limited, we are committed to delivering
              advertising solutions that help our clients achieve their
              marketing goals and grow their business.
              {/* Contact us today to learn
              more about how we can help you reach your target audience and
              increase your return on investment. */}
            </p>
          </div>
        </div>
      </section>
    </HelmetProvider>
  );
};

export default AdvertisingSolutions;

/*

Advertising Solutions

At Bindassdeal Private Limited, we offer a wide range of advertising solutions that help businesses of all sizes reach their target audience and achieve their marketing goals. Our team of experts can develop customized advertising campaigns that are tailored to your business needs and provide maximum return on investment.
Our advertising solutions include:
-Display Advertising: We can create visually appealing ads that are displayed on relevant websites and social media platforms. Our team can help you target specific audiences based on their demographics, interests, and browsing behavior, and ensure that your ads are displayed to the right people at the right time.
-Search Engine Advertising: We can create pay-per-click (PPC) campaigns that appear at the top of search engine results pages. Our team can help you choose the right keywords, create engaging ad copy, and optimize your bids to ensure that your ads are seen by potential customers.
-Social Media Advertising: We can create ads that are displayed on social media platforms such as Facebook, Instagram, and Twitter. Our team can help you target specific audiences based on their location, interests, and behavior, and ensure that your ads are displayed to the people most likely to engage with your brand.
-Video Advertising: We can create video ads that are displayed on YouTube and other video-sharing platforms. Our team can help you create engaging videos that showcase your products or services, and target specific audiences based on their viewing behavior.
-Programmatic Advertising: We can help you automate your advertising campaigns using programmatic technology. Our team can help you optimize your campaigns in real-time, ensuring that your ads are displayed to the right people at the right time, and that your budget is being used effectively.
At Bindassdeal Private Limited, we are committed to delivering advertising solutions that help our clients achieve their marketing goals and grow their business. Contact us today to learn more about how we can help you reach your target audience and increase your return on investment.

*/
