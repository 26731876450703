import React from "react";

import classes from "./About.module.css";

const About = () => {
  return (
    <section className={classes.about}>
      <div className={classes.container}>
        <div className={classes.aboutEl}>
          <div className={`${classes.aboutImg} ${classes.aboutImg1}`}>
            <img
              src={require("../../../../resources/image/brain-img.png")}
              alt="img"
              className={`${classes.img} ${classes.img1}`}
            />
          </div>
          <div className={classes.aboutDescText}>
            <h3>BindassDeal Intelligence</h3>
            <p>
              You're off to a winning start with Clutch. We’re rated as the best
              fit partner on their global ‘AI Leaders Matrix’.<br></br>With deep
              domain expertise in machine learning, neural networks, deep
              learning, NLP, and computer vision, we build AI-powered products
              tailored to fulfill your business vision. From concept to
              delivery. <br></br>Find out why every CXO we work with calls us
              "communicative, processdriven, and reliable".
            </p>
            <button
              type="button"
              className={classes.aboutBtn}
              onClick={() => {}}
            >
              Get Started
            </button>
          </div>
          <img
            src={require("../../../../resources/image/plus-pattern.png")}
            alt="plus-pattern"
            className={classes.plusSvg}
          />
        </div>
        <div className={classes.aboutEl}>
          <div className={classes.aboutDescText}>
            <h3>
              Combining AI with ML<br></br>and Blockchain
            </h3>
            <p>
              You're off to a winning start with Clutch. We’re rated as the best
              fit partner on their global ‘AI Leaders Matrix’.<br></br>With deep
              domain expertise in machine learning, neural networks, deep
              learning, NLP, and computer vision, we build AI-powered products
              tailored to fulfill your business vision. From concept to
              delivery.
              <br></br>Find out why every CXO we work with calls us
              "communicative, processdriven, and reliable".
            </p>
          </div>
          <div className={`${classes.aboutImg} ${classes.aboutImg2}`}>
            <img
              src={require("../../../../resources/image/blockchain-img.png")}
              alt="img"
              className={`${classes.img} ${classes.img2}`}
            />
          </div>
        </div>
        <img
          src={require("../../../../resources/image/bubbles.png")}
          alt="bubble-svg"
          className={classes.bubbleSvg}
        />
        <img
          src={require("../../../../resources/image/lines.png")}
          alt="lines-svg"
          className={classes.linesSvg}
        />
      </div>
    </section>
  );
};

export default About;
