import React from "react";
// import { Helmet } from "react-helmet";
import { Helmet, HelmetProvider } from "react-helmet-async";
import classes from "./SoftwareDevelopment.module.css";

const SoftwareDevelopment = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>
          Top 10 Software Development Companies in India | Best Software
          Development companies in India | Best Software Service Provider in
          Mumbai
        </title>
        <meta
          name="description"
          content="BindassDeal is one of the best software development companies in India and has aided thousands of businesses in reaching their desired business goals."
        />
      </Helmet>
      <section className={classes.hero}>
        <div className={classes.title}>
          <div className={classes.container}>
            <h5 className={classes.pageName}>Software Development</h5>
            {/* <h3 className={classes.headingTitle}>
        <span>Bindassdeal</span> Private Limited
      </h3> */}
            <p className={classes.headingDesc}>
              {/* At Bindassdeal Private Limited, we */}
              We provide customized software development services that help
              businesses of all sizes transform their operations and achieve
              their goals.
              {/* Our team of experienced software developers, designers, and project managers can deliver
              solutions tailored to your unique business needs, whether you
              require a new software application, an update to an existing
              system, or integration with other systems. */}
            </p>
          </div>
        </div>
      </section>
      <section className={classes.about}>
        <div className={classes.container}>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1607705703571-c5a8695f18f6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Custom Software Development</h3>
              <p>
                We can develop custom software solutions that are tailored to
                your business needs, ensuring that your software applications
                are efficient, scalable, and user-friendly.
              </p>
            </div>
          </div>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1472&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Web Application Development</h3>
              <p>
                Our team can develop web applications that enable you to connect
                with customers, partners, and other stakeholders through
                web-based platforms.
              </p>
            </div>
          </div>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1526498460520-4c246339dccb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Mobile Application Development</h3>
              <p>
                We can develop mobile applications that help you reach your
                customers on the go, whether through iOS or Android platforms.
              </p>
            </div>
          </div>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1583508915901-b5f84c1dcde1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Software Integration</h3>
              <p>
                We can integrate your software applications with other systems,
                ensuring that your software solutions work seamlessly with your
                existing infrastructure.
              </p>
            </div>
          </div>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1558494949-ef010cbdcc31?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1634&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Cloud Computing</h3>
              <p>
                We can provide cloud computing solutions that enable you to
                access your software applications from anywhere, at any time,
                and on any device.
              </p>
            </div>
          </div>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1573495627361-d9b87960b12d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1469&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Software Maintenance and Support</h3>
              <p>
                Our team can provide ongoing maintenance and support services,
                ensuring that your software applications continue to meet your
                business needs and remain up-to-date with the latest technology
                trends.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className={classes.summary}>
        <div className={classes.container}>
          <div className={classes.summaryText}>
            <p>
              At Bindassdeal Private Limited, we are committed to delivering
              software solutions that help our clients achieve their business
              goals.
              {/* Contact us today to learn more about how we can help you
              transform your operations and drive your business forward. */}
            </p>
          </div>
        </div>
      </section>
    </HelmetProvider>
  );
};

export default SoftwareDevelopment;

/*

Software development

At Bindassdeal Private Limited, we provide customized software development services that help businesses of all sizes transform their operations and achieve their goals. Our team of experienced software developers, designers, and project managers can deliver solutions tailored to your unique business needs, whether you require a new software application, an update to an existing system, or integration with other systems.
Our software development services include:
-Custom Software Development: We can develop custom software solutions that are tailored to your business needs, ensuring that your software applications are efficient, scalable, and user-friendly.
-Web Application Development: Our team can develop web applications that enable you to connect with customers, partners, and other stakeholders through web-based platforms.
-Mobile Application Development: We can develop mobile applications that help you reach your customers on the go, whether through iOS or Android platforms.
-Software Integration: We can integrate your software applications with other systems, ensuring that your software solutions work seamlessly with your existing infrastructure.
-Cloud Computing: We can provide cloud computing solutions that enable you to access your software applications from anywhere, at any time, and on any device.
-Software Maintenance and Support: Our team can provide ongoing maintenance and support services, ensuring that your software applications continue to meet your business needs and remain up-to-date with the latest technology trends.
At Bindassdeal Private Limited, we are committed to delivering software solutions that help our clients achieve their business goals. Contact us today to learn more about how we can help you transform your operations and drive your business forward.

*/
