import React from "react";
// import { Helmet } from "react-helmet";
import { Helmet, HelmetProvider } from "react-helmet-async";
import classes from "./DataScienceAIServices.module.css";

const DataScienceAIServices = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>
          Data Science AI Development in Mumbai | Artificial Intelligence Course
          in Mumbai | Data Science & AI Services in Mumbai
        </title>
        <meta
          name="description"
          content="We have a team of experts proficient in data science and AI development in Mumbai ready to build custom solutions for your business."
        />
      </Helmet>
      <section className={classes.hero}>
        <div className={classes.title}>
          <div className={classes.container}>
            <h5 className={classes.pageName}>Data Science & AI</h5>
            {/* <h3 className={classes.headingTitle}>
        <span>Bindassdeal</span> Private Limited
      </h3> */}
            <p className={classes.headingDesc}>
              {/* At Bindassdeal Private Limited, we */}
              We offer data science and AI services that help businesses of all
              sizes leverage the power of data and artificial intelligence to
              improve their operations, enhance customer experience, and drive
              growth.
              {/* Our team of experienced data scientists, machine learning engineers, and AI
              experts can deliver solutions that are tailored to your unique
              business needs, whether you need to build a predictive model,
              develop a chatbot, or implement a computer vision system. */}
            </p>
          </div>
        </div>
      </section>
      <section className={classes.about}>
        <div className={classes.container}>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1588600878108-578307a3cc9d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1476&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Data Analysis and Management</h3>
              <p>
                We can help you collect, manage, and analyze your data to
                uncover hidden patterns, trends, and insights that can drive
                business decisions and improve performance.
              </p>
            </div>
          </div>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1614028674026-a65e31bfd27c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Predictive Modelling</h3>
              <p>
                We can help you build predictive models that enable you to
                forecast future trends, identify opportunities, and mitigate
                risks.
              </p>
            </div>
          </div>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1485827404703-89b55fcc595e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Natural Language Processing (NLP)</h3>
              <p>
                We can help you develop NLP models and chatbots that enable you
                to automate customer interactions, improve engagement, and
                reduce costs.
              </p>
            </div>
          </div>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1504639725590-34d0984388bd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Computer Vision</h3>
              <p>
                We can help you implement computer vision systems that enable
                you to analyze images and videos, detect objects and faces, and
                automate tasks.
              </p>
            </div>
          </div>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1516110833967-0b5716ca1387?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Machine Learning</h3>
              <p>
                We can help you leverage machine learning techniques to automate
                repetitive tasks, optimize business processes, and improve
                efficiency.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className={classes.summary}>
        <div className={classes.container}>
          <div className={classes.summaryText}>
            <p>
              At Bindassdeal Private Limited, we are committed to delivering
              data science and AI solutions that help our clients extract value
              from their data and achieve their business goals.
              {/* Contact us today
              to learn more about how we can help you leverage data science and
              AI to drive growth and innovation */}
            </p>
          </div>
        </div>
      </section>
    </HelmetProvider>
  );
};

export default DataScienceAIServices;

/*

Data Science and AI Services

At Bindassdeal Private Limited, we offer data science and AI services that help businesses of all sizes leverage the power of data and artificial intelligence to improve their operations, enhance customer experience, and drive growth. Our team of experienced data scientists, machine learning engineers, and AI experts can deliver solutions that are tailored to your unique business needs, whether you need to build a predictive model, develop a chatbot, or implement a computer vision system.
Our data science and AI services include:
-Data Analysis and Management: We can help you collect, manage, and analyze your data to uncover hidden patterns, trends, and insights that can drive business decisions and improve performance.
-Predictive Modeling: We can help you build predictive models that enable you to forecast future trends, identify opportunities, and mitigate risks.
-Natural Language Processing (NLP): We can help you develop NLP models and chatbots that enable you to automate customer interactions, improve engagement, and reduce costs.
-Computer Vision: We can help you implement computer vision systems that enable you to analyze images and videos, detect objects and faces, and automate tasks.
-Machine Learning: We can help you leverage machine learning techniques to automate repetitive tasks, optimize business processes, and improve efficiency.
At Bindassdeal Private Limited, we are committed to delivering data science and AI solutions that help our clients extract value from their data and achieve their business goals. Contact us today to learn more about how we can help you leverage data science and AI to drive growth and innovation

*/
