import React from "react";
// import { Helmet } from "react-helmet";
import { Helmet, HelmetProvider } from "react-helmet-async";
import classes from "./PermanentPlacements.module.css";

const PermanentPlacements = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>
          Hiring Process Management in Chicago | Hiring & Recruiting Services in
          Los Angeles
        </title>
        <meta
          name="description"
          content="Hiring process management in Chicago is an essential part of any business. In Chicago, there are many employers that offer recruitment services to help with the hiring process."
        />
      </Helmet>
      <section className={classes.hero}>
        <div className={classes.title}>
          <div className={classes.container}>
            <h5 className={classes.pageName}>Permanent Placements</h5>
            {/* <h3 className={classes.headingTitle}>
        <span>Bindassdeal</span> Private Limited
      </h3> */}
            <p className={classes.headingDesc}>
              {/* At Bindassdeal Private Limited, we */}
              We understand that permanent placements can be a critical
              component of any organization's growth strategy.
              {/* That's why we offer comprehensive permanent
              placement solutions that help our clients find the right IT talent
              to support their business objectives. */}
            </p>
          </div>
        </div>
      </section>
      <section className={classes.about}>
        <div className={classes.container}>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1622676666769-1a0407cf10a7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Talent Acquisition</h3>
              <p>
                We have access to a pool of highly skilled IT professionals who
                are looking for permanent employment opportunities. We can help
                our clients find the right talent by leveraging our extensive
                network of candidates, in addition to posting job listings on
                popular job boards and social media platforms.
              </p>
            </div>
          </div>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1552582305-6b778426ab60?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Candidate Screening</h3>
              <p>
                We take the time to understand our clients' needs and work
                closely with them to develop a list of requirements for each
                position. Our team of recruitment specialists then screen
                potential candidates to ensure that they meet or exceed these
                requirements, and to verify their technical skills and cultural
                fit with our clients' organizations.
              </p>
            </div>
          </div>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1616587894289-86480e533129?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Hiring Process Management</h3>
              <p>
                We manage the entire hiring process, from initial candidate
                screening to job offer negotiations. Our team of experts work
                closely with our clients to ensure that the hiring process is
                smooth and efficient, and that they have access to the
                information they need to make informed hiring decisions.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <section className={classes.summary}>
        <div className={classes.container}>
          <div className={classes.summaryText}>
            <p>
              At Bindassdeal Private Limited, we are committed to delivering
              data science and AI solutions that help our clients extract value
              from their data and achieve their business goals. Contact us today
              to learn more about how we can help you leverage data science and
              AI to drive growth and innovation
            </p>
          </div>
        </div>
      </section> */}
    </HelmetProvider>
  );
};

export default PermanentPlacements;

/*

Permanent Placements

At Bindassdeal Private Limited, we understand that permanent placements can be a critical component of any organization's growth strategy. That's why we offer comprehensive permanent placement solutions that help our clients find the right IT talent to support their business objectives.
Our permanent placement solutions include:
-Talent Acquisition: We have access to a pool of highly skilled IT professionals who are looking for permanent employment opportunities. We can help our clients find the right talent by leveraging our extensive network of candidates, in addition to posting job listings on popular job boards and social media platforms.
-Candidate Screening: We take the time to understand our clients' needs and work closely with them to develop a list of requirements for each position. Our team of recruitment specialists then screen potential candidates to ensure that they meet or exceed these requirements, and to verify their technical skills and cultural fit with our clients' organizations.
-Hiring Process Management: We manage the entire hiring process, from initial candidate screening to job offer negotiations. Our team of experts work closely with our clients to ensure that the hiring process is smooth and efficient, and that they have access to the information they need to make informed hiring decisions.
-By leveraging our permanent placement solutions,

*/
