import React from "react";
// import { Helmet } from "react-helmet";
import { Helmet, HelmetProvider } from "react-helmet-async";
import classes from "./GamingSolutions.module.css";

const GamingSolutions = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>
          Top Game Development Companies in Singapore | Game Design in Los
          Angeles | Game Art and Animation in Mumbai
        </title>
        <meta
          name="description"
          content="If you are looking for a reliable top game development companies in singapore, you can trust Bindassdeal We have helped many businesses create beautiful and engaging websites that have helped them achieve success."
        />
      </Helmet>
      <section className={classes.hero}>
        <div className={classes.title}>
          <div className={classes.container}>
            <h5 className={classes.pageName}>Gaming</h5>
            {/* <h3 className={classes.headingTitle}>
            <span>Bindassdeal</span> Private Limited
          </h3> */}
            <p className={classes.headingDesc}>
              {/* At Bindassdeal Private Limited, we */}
              We offer gaming solutions that help game developers and publishers
              create engaging and immersive gaming experiences that captivate
              players and drive growth.
              {/* Our team of experienced game designers, developers, and engineers can deliver solutions that are tailored
              to your unique business needs, whether you need to develop a
              mobile game, a console game, or an online multiplayer game. */}
            </p>
          </div>
        </div>
      </section>
      <section className={classes.about}>
        <div className={classes.container}>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1534423861386-85a16f5d13fd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Game Development</h3>
              <p>
                We can help you develop games for various platforms, including
                mobile, console, PC, and online. Our team can handle the entire
                development process, from ideation to launch, ensuring
                high-quality and engaging games that meet your business goals.
              </p>
            </div>
          </div>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1531403009284-440f080d1e12?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Game Design</h3>
              <p>
                We can help you design games that are fun, engaging, and
                immersive. Our team can create game mechanics, game worlds, and
                characters that resonate with your target audience and elevate
                your game to the next level.
              </p>
            </div>
          </div>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1548031045-0ce1bd4a94ca?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Game Art and Animation</h3>
              <p>
                We can help you create high-quality game art and animation that
                brings your game to life. Our team can create 2D and 3D
                graphics, animations, and visual effects that enhance the player
                experience and make your game stand out.
              </p>
            </div>
          </div>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1560253023-3ec5d502959f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Game Testing and Quality Assurance</h3>
              <p>
                We can help you test and ensure the quality of your game, from
                gameplay mechanics to user experience and performance. Our team
                can identify and fix bugs and issues, ensuring a smooth and
                enjoyable gaming experience for your players.
              </p>
            </div>
          </div>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1635187834534-d1fa994fcabb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1469&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Game Monetization</h3>
              <p>
                We can help you monetize your game and generate revenue, whether
                through in-game purchases, ads, or other revenue streams. Our
                team can help you design and implement monetization strategies
                that align with your business goals and player experience.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className={classes.summary}>
        <div className={classes.container}>
          <div className={classes.summaryText}>
            <p>
              At Bindassdeal Private Limited, we are committed to delivering
              gaming solutions that help our clients create games that captivate
              players and drive growth.
              {/* Contact us today to learn more about how
              we can help you leverage gaming to reach your business goals. */}
            </p>
          </div>
        </div>
      </section>
    </HelmetProvider>
  );
};

export default GamingSolutions;

/*

Gaming Solutions

At Bindassdeal Private Limited, we offer gaming solutions that help game developers and publishers create engaging and immersive gaming experiences that captivate players and drive growth. Our team of experienced game designers, developers, and engineers can deliver solutions that are tailored to your unique business needs, whether you need to develop a mobile game, a console game, or an online multiplayer game.
Our gaming solutions include:
-Game Development: We can help you develop games for various platforms, including mobile, console, PC, and online. Our team can handle the entire development process, from ideation to launch, ensuring high-quality and engaging games that meet your business goals.
-Game Design: We can help you design games that are fun, engaging, and immersive. Our team can create game mechanics, game worlds, and characters that resonate with your target audience and elevate your game to the next level.
-Game Art and Animation: We can help you create high-quality game art and animation that brings your game to life. Our team can create 2D and 3D graphics, animations, and visual effects that enhance the player experience and make your game stand out.
-Game Testing and Quality Assurance: We can help you test and ensure the quality of your game, from gameplay mechanics to user experience and performance. Our team can identify and fix bugs and issues, ensuring a smooth and enjoyable gaming experience for your players.
-Game Monetization: We can help you monetize your game and generate revenue, whether through in-game purchases, ads, or other revenue streams. Our team can help you design and implement monetization strategies that align with your business goals and player experience.
At Bindassdeal Private Limited, we are committed to delivering gaming solutions that help our clients create games that captivate players and drive growth. Contact us today to learn more about how we can help you leverage gaming to reach your business goals.

*/
