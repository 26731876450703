import React from "react";
// import { Helmet } from "react-helmet";
import { Helmet, HelmetProvider } from "react-helmet-async";
import classes from "./EcommerceSolutions.module.css";

const EcommerceSolutions = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>
          Top E-Commerce Developers in Los Angeles | Ecommerce Web Design
          Company in mumbai | Ecommerce Mobile App Development in Chicago
        </title>
        <meta
          name="description"
          content="Businesses can also benefit from Ecommerce Web Design Companies in Mumbai that help create a website to promote and market the business."
        />
      </Helmet>
      <section className={classes.hero}>
        <div className={classes.title}>
          <div className={classes.container}>
            <h5 className={classes.pageName}>Ecommerce</h5>
            {/* <h3 className={classes.headingTitle}>
        <span>Bindassdeal</span> Private Limited
      </h3> */}
            <p className={classes.headingDesc}>
              {/* At Bindassdeal Private Limited, we */}
              We provide comprehensive ecommerce solutions that help businesses
              of all sizes sell products and services online.
              {/* Our experienced team of designers, developers,
              and engineers can build customized ecommerce solutions that cater
              to your business requirements and provide an exceptional user
              experience to your customers. */}
            </p>
          </div>
        </div>
      </section>
      <section className={classes.about}>
        <div className={classes.container}>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1487014679447-9f8336841d58?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1605&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Ecommerce Website Development</h3>
              <p>
                We can build an ecommerce website that is tailored to your
                business needs and optimized for conversions. Our team can
                create a user-friendly interface, intuitive navigation, and
                streamlined checkout process that makes it easy for your
                customers to purchase products and services.
              </p>
            </div>
          </div>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1605902711622-cfb43c4437b5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1469&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Ecommerce Mobile App Development</h3>
              <p>
                We can develop a mobile app for your ecommerce store that
                provides a seamless shopping experience to your customers. Our
                team can create an app that is compatible with both iOS and
                Android devices and has features such as push notifications,
                mobile payments, and in-app messaging.
              </p>
            </div>
          </div>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1449247666642-264389f5f5b1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1469&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Ecommerce Platform Integration</h3>
              <p>
                We can integrate your ecommerce store with various third-party
                platforms such as payment gateways, shipping providers, and
                inventory management systems. Our team can ensure that the
                integration is seamless and provides a hassle-free experience to
                your customers.
              </p>
            </div>
          </div>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1599658880436-c61792e70672?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Ecommerce Analytics and Reporting</h3>
              <p>
                We can help you track your ecommerce performance and provide
                insights that enable you to make data-driven decisions. Our team
                can set up analytics tools and generate reports that provide you
                with detailed information about your customers, sales, and
                website performance.
              </p>
            </div>
          </div>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1598946114934-8cf259aa241d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Ecommerce Security and Compliance</h3>
              <p>
                We can ensure that your ecommerce store is secure and compliant
                with industry standards and regulations. Our team can implement
                security measures such as SSL encryption, two-factor
                authentication, and regular security audits to protect your
                business and customers' data.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className={classes.summary}>
        <div className={classes.container}>
          <div className={classes.summaryText}>
            <p>
              At Bindassdeal Private Limited, we are committed to delivering
              ecommerce solutions that help our clients grow their business and
              reach their target audience.
              {/* Contact us today to learn more about
              how we can help you leverage ecommerce to achieve your business
              goals. */}
            </p>
          </div>
        </div>
      </section>
    </HelmetProvider>
  );
};

export default EcommerceSolutions;

/*

Ecommerce Solutions

At Bindassdeal Private Limited, we provide comprehensive ecommerce solutions that help businesses of all sizes sell products and services online. Our experienced team of designers, developers, and engineers can build customized ecommerce solutions that cater to your business requirements and provide an exceptional user experience to your customers.
Our ecommerce solutions include:
-Ecommerce Website Development: We can build an ecommerce website that is tailored to your business needs and optimized for conversions. Our team can create a user-friendly interface, intuitive navigation, and streamlined checkout process that makes it easy for your customers to purchase products and services.
-Ecommerce Mobile App Development: We can develop a mobile app for your ecommerce store that provides a seamless shopping experience to your customers. Our team can create an app that is compatible with both iOS and Android devices and has features such as push notifications, mobile payments, and in-app messaging.
-Ecommerce Platform Integration: We can integrate your ecommerce store with various third-party platforms such as payment gateways, shipping providers, and inventory management systems. Our team can ensure that the integration is seamless and provides a hassle-free experience to your customers.
-Ecommerce Analytics and Reporting: We can help you track your ecommerce performance and provide insights that enable you to make data-driven decisions. Our team can set up analytics tools and generate reports that provide you with detailed information about your customers, sales, and website performance.
-Ecommerce Security and Compliance: We can ensure that your ecommerce store is secure and compliant with industry standards and regulations. Our team can implement security measures such as SSL encryption, two-factor authentication, and regular security audits to protect your business and customers' data.
At Bindassdeal Private Limited, we are committed to delivering ecommerce solutions that help our clients grow their business and reach their target audience. Contact us today to learn more about how we can help you leverage ecommerce to achieve your business goals.

*/
