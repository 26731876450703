import React from "react";
// import { Helmet } from "react-helmet";
import { Helmet, HelmetProvider } from "react-helmet-async";
import classes from "./SocialMediaMarketingServices.module.css";

const SocialMediaMarketingServices = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>
          Social Media Marketing Agency in Delhi-NCR | SMM Agency in Dubai |
          Best Social Media Agencies in Singapore
        </title>
        <meta
          name="description"
          content="Our social media marketing agency in Delhi ncr is known to provide premium solutions to promote your business online."
        />
      </Helmet>
      <section className={classes.hero}>
        <div className={classes.title}>
          <div className={classes.container}>
            <h5 className={classes.pageName}>Social Media Marketing</h5>
            {/* <h3 className={classes.headingTitle}>
        <span>Bindassdeal</span> Private Limited
      </h3> */}
            <p className={classes.headingDesc}>
              {/* At Bindassdeal Private Limited, we */}
              We offer social media marketing services that help businesses of
              all sizes reach their target audience, build brand awareness, and
              engage with customers through social media platforms.
              {/* Our team of experienced social media specialists can help you develop a social media strategy that is
              tailored to your unique business needs, whether you want to build
              a social media presence from scratch, or improve your existing
              social media efforts. */}
            </p>
          </div>
        </div>
      </section>
      <section className={classes.about}>
        <div className={classes.container}>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1611162617213-7d7a39e9b1d7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Social Media Strategy Development</h3>
              <p>
                We can help you develop a social media strategy that aligns with
                your business goals, target audience, and budget, ensuring that
                your social media efforts are effective and efficient.
              </p>
            </div>
          </div>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1432888622747-4eb9a8efeb07?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDE4fHx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=500&q=60"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Social Media Account Setup and Management</h3>
              <p>
                We can help you set up and manage social media accounts on
                various platforms, such as Facebook, Twitter, LinkedIn,
                Instagram, and YouTube, ensuring that your brand is represented
                consistently across all channels.
              </p>
            </div>
          </div>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1432888498266-38ffec3eaf0a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1474&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Social Media Content Creation</h3>
              <p>
                We can create high-quality, engaging, and shareable content that
                resonates with your target audience, such as blog posts,
                infographics, videos, and social media posts.
              </p>
            </div>
          </div>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1563986768494-4dee2763ff3f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Social Media Advertising</h3>
              <p>
                We can create and manage social media advertising campaigns that
                help you reach your target audience and achieve your business
                objectives, such as lead generation, website traffic, or brand
                awareness.
              </p>
            </div>
          </div>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1560472354-b33ff0c44a43?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1551&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Social Media Analytics and Reporting</h3>
              <p>
                We can provide detailed analytics and reporting on your social
                media performance, such as engagement rates, reach, impressions,
                and conversions, enabling you to measure the effectiveness of
                your social media efforts and make data-driven decisions.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className={classes.summary}>
        <div className={classes.container}>
          <div className={classes.summaryText}>
            <p>
              At Bindassdeal Private Limited, we are committed to delivering
              social media solutions that help our clients achieve their
              business goals.
              {/* Contact us today to learn more about how we can
              help you reach your target audience, build brand awareness, and
              engage with your customers through social media platforms. */}
            </p>
          </div>
        </div>
      </section>
    </HelmetProvider>
  );
};

export default SocialMediaMarketingServices;

/*

Social Media Marketing Services

At Bindassdeal Private Limited, we offer social media marketing services that help businesses of all sizes reach their target audience, build brand awareness, and engage with customers through social media platforms. Our team of experienced social media specialists can help you develop a social media strategy that is tailored to your unique business needs, whether you want to build a social media presence from scratch, or improve your existing social media efforts.
Our social media marketing services include:
-Social Media Strategy Development: We can help you develop a social media strategy that aligns with your business goals, target audience, and budget, ensuring that your social media efforts are effective and efficient.
-Social Media Account Setup and Management: We can help you set up and manage social media accounts on various platforms, such as Facebook, Twitter, LinkedIn, Instagram, and YouTube, ensuring that your brand is represented consistently across all channels.
-Social Media Content Creation: We can create high-quality, engaging, and shareable content that resonates with your target audience, such as blog posts, infographics, videos, and social media posts.
-Social Media Advertising: We can create and manage social media advertising campaigns that help you reach your target audience and achieve your business objectives, such as lead generation, website traffic, or brand awareness.
-Social Media Analytics and Reporting: We can provide detailed analytics and reporting on your social media performance, such as engagement rates, reach, impressions, and conversions, enabling you to measure the effectiveness of your social media efforts and make data-driven decisions.
At Bindassdeal Private Limited, we are committed to delivering social media solutions that help our clients achieve their business goals. Contact us today to learn more about how we can help you reach your target audience, build brand awareness, and engage with your customers through social media platforms.

*/
