import React from "react";
import classes from "./Achievements.module.css";

const Achievements = () => {
  return (
    <section className={classes.achievements}>
      <div className={classes.container}>
        <h3>Achievements</h3>
        <p>
          We at Bindassdeal Private Limited have successfully delivered a
          complex software project on time and within budget. Developing
          software solutions that meet or exceed industry standards for
          security, scalability, and usability. Successfully delivering dozens
          of software projects on time and within budget, resulting in high
          customer satisfaction rates and repeat business.
        </p>
        <div className={classes.achievementContainer}>
          <p>
            <img
              src={require("../../../../resources/image/brain-check-times.png")}
              alt="img"
              className={""}
            />
            <span>
              Delivered the NFT, Block chain, Solidity projects within a
              timeline.
            </span>
          </p>
          <p>
            <img
              src={require("../../../../resources/image/brain-check-times.png")}
              alt="img"
              className={""}
            />
            <span>Delivered the AI software as per clients' requirement.</span>
          </p>
          <p>
            <img
              src={require("../../../../resources/image/brain-check-times.png")}
              alt="img"
              className={""}
            />
            <span>
              Software related to ERP and business solving product development.
            </span>
          </p>
        </div>
      </div>
    </section>
  );
};

export default Achievements;
