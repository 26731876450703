import React from "react";
import { useState } from "react";
import { useEffect } from "react";

import classes from "./Portfolio.module.css";

// const DUMMY_PORTFOLIO_CATEGORIES = [
//   { id: "c1", name: "All" },
//   { id: "c2", name: "Blockchain" },
//   { id: "c3", name: "Data Analytics" },
//   { id: "c4", name: "Websites" },
//   { id: "c5", name: "Mobile Apps" }
// ];

const DUMMY_PORTFOLIO_CATEGORIES = [
  { id: "p1", name: "All", isSelected: true },
  { id: "p2", name: "Blockchain", isSelected: false },
  { id: "p3", name: "Data Analytics", isSelected: false },
  { id: "p4", name: "Websites", isSelected: false },
  { id: "p5", name: "Mobile Apps", isSelected: false }
];

// prettier-ignore
const DUMMY_CONTENT = [
  { id: "c1", name: "Foundation Mixer", img: "port-foundation-mixer.jpg", categ: "Blockchain", },
  { id: "c2", name: "Foundation AI NFT", img: "port-foundation-nft.jpg", categ: "Blockchain", },
  { id: "c3", name: "Half Leash", img: "port-half-leash.jpg", categ: "Blockchain", },
  { id: "c4", name: "Happy Bear", img: "port-happy-bear.jpg", categ: "Blockchain", },
  { id: "c5", name: "Gas Guzzler", img: "port-gas-guzzlrs.jpg", categ: "Websites", },
  { id: "c6", name: "Blockchain", img: "port-blockchain.webp", categ: "Blockchain", },
  { id: "c8", name: "Mobile App", img: "port-mobile-app-3.webp", categ: "Mobile Apps", },
  { id: "c9", name: "Mobile App", img: "port-mobile-app-4.webp", categ: "Mobile Apps", },
  { id: "c10", name: "Mobile App", img: "port-mobile-app-5.webp", categ: "Mobile Apps", },
  { id: "c11", name: "Mobile App", img: "port-mobile-app-6.webp", categ: "Mobile Apps", },
  { id: "c7", name: "Blockchain", img: "port-blockchain-2.webp", categ: "Blockchain", },
  { id: "c12", name: "Mobile App", img: "port-mobile-app-7.webp", categ: "Mobile Apps", },
  { id: "c13", name: "Mobile App", img: "port-mobile-app-8.webp", categ: "Mobile Apps", },
  { id: "c14", name: "Data Analytics", img: "port-data-analytics.webp", categ: "Data Analytics", },
  { id: "c15", name: "Data Analytics", img: "port-data-analytics-3.jpg", categ: "Data Analytics", },
  { id: "c16", name: "Data Analytics", img: "port-data-analytics-2.webp", categ: "Data Analytics", },
  { id: "c17", name: "Data Analytics", img: "port-data-analytics-4.webp", categ: "Data Analytics", },
  { id: "c18", name: "Data Analytics", img: "port-data-analytics-5.jpg", categ: "Data Analytics", },
  { id: "c19", name: "Websites", img: "port-websites-2.jpg", categ: "Websites", },
  { id: "c20", name: "Websites", img: "port-websites-3.webp", categ: "Websites", },
  { id: "c21", name: "Websites", img: "port-websites-4.png", categ: "Websites", },
  { id: "c22", name: "Websites", img: "port-websites-5.png", categ: "Websites", },
  { id: "c23", name: "Websites", img: "port-websites-6.webp", categ: "Websites", },
];

const Portfolio = () => {
  //   const [categories, setCategories] = useState([]);
  const [categories, setCategories] = useState(DUMMY_PORTFOLIO_CATEGORIES);
  const [category, setCategory] = useState("All");

  //   useEffect(() => {
  //     const tempArr = DUMMY_PORTFOLIO_CATEGORIES.map((el, i) => {
  //       return { ...el, isSelected: false };
  //     });

  //     setCategories(tempArr);
  //   }, []);

  const categoryHandler = (category) => {
    const tempArr = categories.map((el, i) => {
      return { ...el, isSelected: el.name === category ? true : false };
    });

    setCategories(tempArr);
    setCategory(category);
  };

  return (
    <section className={classes.section}>
      <div className={classes.container}>
        <h4 className={classes.sectionTitle}>Portfolio</h4>
        <div className={classes.horizontalRule} />
        <div className={classes.categoryContainer}>
          <ul className={classes.categoryList}>
            {categories.map((category, i) => {
              const { id, name, isSelected } = category;

              return (
                <li key={id} className={`${classes.category}`}>
                  <button
                    className={`${classes.categoryBtn} ${
                      isSelected ? classes.active : ""
                    }`}
                    onClick={() => categoryHandler(name)}
                  >
                    {name}
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
        <div className={classes.contentContainer}>
          <ul className={classes.contentList}>
            {category === "All" &&
              DUMMY_CONTENT.map((el, i) => {
                const { id, name, img, categ } = el;

                return (
                  <li key={id} className={classes.contentEl}>
                    <div className={classes.contentImgTxt}>
                      <img
                        src={require(`../../../resources/image/${img}`)}
                        alt={name}
                        className={classes.contentImg}
                      />
                      {/* <span className={classes.textBox}>
                        <p className={classes.contentText}>{name}</p>
                      </span> */}
                    </div>
                  </li>
                );
              })}
            {category !== "All" &&
              DUMMY_CONTENT.filter((el, i) => el.categ === category).map(
                (el, i) => {
                  const { id, name, img, categ } = el;

                  return (
                    <li key={id} className={classes.contentEl}>
                      <div className={classes.contentImgTxt}>
                        <img
                          src={require(`../../../resources/image/${img}`)}
                          alt={name}
                          className={classes.contentImg}
                        />
                        {/* <span className={classes.textBox}>
                          <p className={classes.contentText}>{name}</p>
                        </span> */}
                      </div>
                    </li>
                  );
                }
              )}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
