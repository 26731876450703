import React from "react";
// import { Helmet } from "react-helmet";
import { Helmet, HelmetProvider } from "react-helmet-async";
import classes from "./RetailSolutions.module.css";

const RetailSolutions = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>
          Point of Sale (POS) System | Inventory Management Software in Mumbai |
          Best Inventory Management Software Providers in Chicago
        </title>
        <meta
          name="description"
          content="Bindassdeal offers Inventory Management Software in Mumbai. This software helps businesses track their inventory, allowing them to identify items that are low in stock and those that need restocking."
        />
      </Helmet>
      <section className={classes.hero}>
        <div className={classes.title}>
          <div className={classes.container}>
            <h5 className={classes.pageName}>Retail</h5>
            {/* <h3 className={classes.headingTitle}>
        <span>Bindassdeal</span> Private Limited
      </h3> */}
            <p className={classes.headingDesc}>
              {/* At Bindassdeal Private Limited, we */}
              We provide comprehensive retail solutions that help businesses of
              all sizes streamline their operations, enhance customer
              experience, and increase revenue.
              {/* Our experienced team of designers, developers, and engineers can build
              customized retail solutions that cater to your business
              requirements and provide an exceptional user experience to your
              customers. */}
            </p>
          </div>
        </div>
      </section>
      <section className={classes.about}>
        <div className={classes.container}>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1556742077-0a6b6a4a4ac4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Point of Sale (POS) System</h3>
              <p>
                We can develop a POS system that is tailored to your business
                needs and helps you manage sales, inventory, and customer data.
                Our team can create a user-friendly interface, intuitive
                navigation, and streamlined checkout process that makes it easy
                for your employees to manage transactions.
              </p>
            </div>
          </div>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1616401784845-180882ba9ba8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Inventory Management System</h3>
              <p>
                We can build an inventory management system that allows you to
                manage stock levels, track inventory movements, and receive
                alerts when items are low in stock. Our team can integrate the
                system with your POS, ecommerce platform, and other systems to
                ensure that your inventory is up-to-date across all channels.
              </p>
            </div>
          </div>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Customer Relationship Management (CRM) System</h3>
              <p>
                We can develop a CRM system that allows you to manage customer
                data, track sales history, and provide personalized marketing
                messages. Our team can create a system that helps you understand
                your customers' needs and preferences, and allows you to provide
                exceptional customer service.
              </p>
            </div>
          </div>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1664455340023-214c33a9d0bd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1632&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Ecommerce Integration</h3>
              <p>
                We can integrate your retail store with your ecommerce platform,
                enabling you to reach a wider audience and sell products online.
                Our team can ensure that the integration is seamless and
                provides a hassle-free experience to your customers.
              </p>
            </div>
          </div>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1591696205602-2f950c417cb9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Retail Analytics and Reporting</h3>
              <p>
                We can help you track your retail performance and provide
                insights that enable you to make data-driven decisions. Our team
                can set up analytics tools and generate reports that provide you
                with detailed information about your sales, inventory, and
                customer behavior.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className={classes.summary}>
        <div className={classes.container}>
          <div className={classes.summaryText}>
            <p>
              At Bindassdeal Private Limited, we are committed to delivering
              retail solutions that help our clients grow their business and
              reach their target audience.
              {/* Contact us today to learn more about
              how we can help you streamline your retail operations and increase
              revenue. */}
            </p>
          </div>
        </div>
      </section>
    </HelmetProvider>
  );
};

export default RetailSolutions;

/*

Retail Solutions

At Bindassdeal Private Limited, we provide comprehensive retail solutions that help businesses of all sizes streamline their operations, enhance customer experience, and increase revenue. Our experienced team of designers, developers, and engineers can build customized retail solutions that cater to your business requirements and provide an exceptional user experience to your customers.
Our retail solutions include:
-Point of Sale (POS) System: We can develop a POS system that is tailored to your business needs and helps you manage sales, inventory, and customer data. Our team can create a user-friendly interface, intuitive navigation, and streamlined checkout process that makes it easy for your employees to manage transactions.
-Inventory Management System: We can build an inventory management system that allows you to manage stock levels, track inventory movements, and receive alerts when items are low in stock. Our team can integrate the system with your POS, ecommerce platform, and other systems to ensure that your inventory is up-to-date across all channels.
-Customer Relationship Management (CRM) System: We can develop a CRM system that allows you to manage customer data, track sales history, and provide personalized marketing messages. Our team can create a system that helps you understand your customers' needs and preferences, and allows you to provide exceptional customer service.
-Ecommerce Integration: We can integrate your retail store with your ecommerce platform, enabling you to reach a wider audience and sell products online. Our team can ensure that the integration is seamless and provides a hassle-free experience to your customers.
-Retail Analytics and Reporting: We can help you track your retail performance and provide insights that enable you to make data-driven decisions. Our team can set up analytics tools and generate reports that provide you with detailed information about your sales, inventory, and customer behavior.
At Bindassdeal Private Limited, we are committed to delivering retail solutions that help our clients grow their business and reach their target audience. Contact us today to learn more about how we can help you streamline your retail operations and increase revenue.

*/
