import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import classes from "./DesktopNav.module.css";

const NestedMenu = ({ menu, setIsMenuOpen }) => {
  const navigate = useNavigate();
  return (
    <div className={classes.menu}>
      <ul className={classes.menuList}>
        {menu.map((item, i) => {
          return (
            <li
              key={item.id}
              className={classes.menuListItem}
              onClick={() => {
                navigate(`${item.page}`);
                setIsMenuOpen(false);
              }}
            >
              {item.link}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const NavLinks = ({ name, menu }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <li
      className={classes["navLinks__list--item"]}
      onMouseEnter={() => setIsMenuOpen(true)}
      onMouseLeave={() => setIsMenuOpen(false)}
    >
      <button type="button" className={classes.linkBtn}>
        <span className={classes.linkTxtSpan}>
          <p className={classes.linkTxt}>{name}</p>
        </span>
        <span className={classes.linkIconSpan}>
          <p className={classes.linkIcon}>
            <i
              className={`fa fa-angle-${isMenuOpen ? "up" : "down"}`}
              aria-hidden="true"
            ></i>
          </p>
        </span>
      </button>
      {isMenuOpen && menu.length > 0 && (
        <NestedMenu menu={menu} setIsMenuOpen={setIsMenuOpen} />
      )}
    </li>
  );
};

const DesktopNav = ({ navigationLinks }) => {
  return (
    <nav className={classes.navLinks}>
      <ul className={classes.navLinks__list}>
        {navigationLinks.map((link, i) => {
          const { id, name, menu } = link;
          return <NavLinks key={id} name={name} menu={menu} />;
        })}
      </ul>
      <button type="button" className={classes.navBtn} onClick={() => {}}>
        Get Started
      </button>
    </nav>
  );
};

export default DesktopNav;
