import React from "react";

import classes from "./Intro.module.css";

const Intro = () => {
  return (
    <section className={classes.intro}>
      <div className={classes.title}>
        <div className={classes.container}>
          <h5 className={classes.pageName}>About Us</h5>
          <h3 className={classes.headingTitle}>
            <span>Bindassdeal</span> Private Limited
          </h3>
          <p className={classes.headingDesc}>
            A leading software development company dedicated to provide
            innovative and scalable technology solutions to businesses of all
            sizes.
          </p>
        </div>
      </div>
      <div className={classes.about}>
        <div className={classes.container}>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1551135049-8a33b5883817?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NDB8fG9mZmljZSUyMG1lZXRpbmd8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>BindassDeal</h3>
              <p>
                Since our inception in 2020, we have been committed to
                delivering exceptional software products and services that drive
                business growth and transformation.
                <br />
                <br />
                That's why we offer a wide range of software development
                services, from custom software development to mobile app
                development, web development, and software integration, Web3,
                Solidity, Blockchain, NFT Marketplace, Meta verse, Data
                Services, AI, ML, Analytics.
                <br />
                <br />
                We leverage the latest technologies and development
                methodologies to ensure that our solutions are scalable, secure,
                and future-proof.
                <br />
                <br />
                Our commitment to excellence has earned us a reputation as a
                trusted partner to businesses across a wide range of industries,
                including healthcare, finance, retail, and education.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Intro;
