import React from "react";
// import { Helmet } from "react-helmet";
import { Helmet, HelmetProvider } from "react-helmet-async";
import classes from "./ResourcingHiringServices.module.css";

const ResourcingHiringServices = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>
          Corporate Team Building Companies in Mumbai | Software Development
          Team Building in Chicago
        </title>
        <meta
          name="description"
          content="Bindassdeal is one of the leading corporate team building companies in Mumbai. They understand that team building is an essential part of an organization."
        />
      </Helmet>
      <section className={classes.hero}>
        <div className={classes.title}>
          <div className={classes.container}>
            <h5 className={classes.pageName}>Resource & Hiring</h5>
            {/* <h3 className={classes.headingTitle}>
        <span>Bindassdeal</span> Private Limited
      </h3> */}
            <p className={classes.headingDesc}>
              {/* At Bindassdeal Private Limited, we */}
              We offer resourcing and hiring services that help businesses of
              all sizes find and hire the right talent for their software
              development needs.
              {/* Our team of experienced recruiters, HR professionals, and project managers can
              deliver solutions that are tailored to your unique business needs,
              whether you need to fill a specific role, build a software
              development team, or scale your software development capacity. */}
            </p>
          </div>
        </div>
      </section>
      <section className={classes.about}>
        <div className={classes.container}>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1573496130407-57329f01f769?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1469&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Talent Acquisition</h3>
              <p>
                We can help you identify and source top talent for your software
                development needs, whether you need software developers, project
                managers, QA analysts, or UX/UI designers.
              </p>
            </div>
          </div>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1554902843-260acd0993f8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Recruitment Process Outsourcing (RPO)</h3>
              <p>
                We can provide end-to-end recruitment process outsourcing
                services that include job posting, candidate sourcing,
                screening, interviewing, and hiring, ensuring that you get the
                best talent in a cost-effective manner.
              </p>
            </div>
          </div>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1557426272-fc759fdf7a8d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Staff Augmentation</h3>
              <p>
                We can provide highly skilled software development resources on
                a contract or project basis, ensuring that you have the right
                resources at the right time to meet your project needs.
              </p>
            </div>
          </div>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1521737852567-6949f3f9f2b5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1447&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Software Development Team Building</h3>
              <p>
                We can help you build and manage a dedicated software
                development team that is aligned with your business goals,
                culture, and technology needs, ensuring that you have the right
                team to deliver your software development projects.
              </p>
            </div>
          </div>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1532618793091-ec5fe9635fbd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>HR and Payroll Management</h3>
              <p>
                We can provide HR and payroll management services that ensure
                compliance with labor laws and regulations, and help you manage
                your workforce effectively.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className={classes.summary}>
        <div className={classes.container}>
          <div className={classes.summaryText}>
            <p>
              At Bindassdeal Private Limited, we are committed to delivering
              resourcing and hiring solutions that help our clients build
              high-performing software development teams and achieve their
              business goals.
              {/* Contact us today to learn more about how we can
              help you find and hire the right talent for your software
              development needs. */}
            </p>
          </div>
        </div>
      </section>
    </HelmetProvider>
  );
};

export default ResourcingHiringServices;

/*

Resourcing and Hiring Services

At Bindassdeal Private Limited, we offer resourcing and hiring services that help businesses of all sizes find and hire the right talent for their software development needs. Our team of experienced recruiters, HR professionals, and project managers can deliver solutions that are tailored to your unique business needs, whether you need to fill a specific role, build a software development team, or scale your software development capacity.
Our resourcing and hiring services include:
-Talent Acquisition: We can help you identify and source top talent for your software development needs, whether you need software developers, project managers, QA analysts, or UX/UI designers.
-Recruitment Process Outsourcing (RPO): We can provide end-to-end recruitment process outsourcing services that include job posting, candidate sourcing, screening, interviewing, and hiring, ensuring that you get the best talent in a cost-effective manner.
-Staff Augmentation: We can provide highly skilled software development resources on a contract or project basis, ensuring that you have the right resources at the right time to meet your project needs.
-Software Development Team Building: We can help you build and manage a dedicated software development team that is aligned with your business goals, culture, and technology needs, ensuring that you have the right team to deliver your software development projects.
-HR and Payroll Management: We can provide HR and payroll management services that ensure compliance with labor laws and regulations, and help you manage your workforce effectively.
At Bindassdeal Private Limited, we are committed to delivering resourcing and hiring solutions that help our clients build high-performing software development teams and achieve their business goals. Contact us today to learn more about how we can help you find and hire the right talent for your software development needs.

*/
