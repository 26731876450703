import React from "react";
import Navigation from "./components/Layout/Navigation/Navigation";
import Footer from "./components/Layout/Footer/Footer";
import Home from "./components/Pages/Home/Home";
import About from "./components/Pages/About/About";
import GamingSolutions from "./components/Pages/GamingSolutions/GamingSolutions";
import AdvertisingSolutions from "./components/Pages/AdvertisingSolutions/AdvertisingSolutions";
import EcommerceSolutions from "./components/Pages/EcommerceSolutions/EcommerceSolutions";
import GeneralStaffingSolutions from "./components/Pages/GeneralStaffingSolutions/GeneralStaffingSolutions";
import OutsourcingServices from "./components/Pages/OutsourcingServices/OutsourcingServices";
import HireTrainDeploy from "./components/Pages/HireTrainDeploy/HireTrainDeploy";
import SoftwareDevelopment from "./components/Pages/SoftwareDevelopment/SoftwareDevelopment";
import EcommerceApplicationServices from "./components/Pages/EcommerceAppServices/EcommerceApplicationServices";
import WebsiteDevelopment from "./components/Pages/WebsiteDevelopment/WebsiteDevelopment";
import SocialMediaMarketingServices from "./components/Pages/DigitalMarketingServices/SocialMediaMarketingServices";
import DesignGraphicServices from "./components/Pages/Design&GraphicServices/DesignGraphicServices";
import ResourcingHiringServices from "./components/Pages/Resourcing&HiringServices/ResourcingHiringServices";
import CryptoBlockchainServices from "./components/Pages/Crypto&BlockchainServices/CryptoBlockchainServices";
import DataScienceAIServices from "./components/Pages/DataScienceAIServices/DataScienceAIServices";
import PermanentPlacements from "./components/Pages/PermanentPlacements/PermanentPlacements";
import FintechSolutions from "./components/Pages/FintechSolutions/FintechSolutions";
import RetailSolutions from "./components/Pages/RetailSolutions/RetailSolutions";
import ContactUs from "./components/Pages/Contact/ContactUs";
import Portfolio from "./components/Pages/Portfolio/Portfolio";
import { Routes, Route } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

import classes from "./App.module.css";

function App() {
  // prettier-ignore
  return (
    <HelmetProvider>
    <div className={classes.container}>
      <Helmet>
        <title>Best mobile application development company in India | pockets budget software development company in India | bindassdeal</title>
        <meta name="description" content="BindassDeal is a leading best mobile application development company in India that specializes in using the latest technologies for businesses to develop innovative software solutions and apps." />
        <meta id="meta-keywords" name="keywords" content="BindassDeal, BindassDeal Pvt. Ltd., AI, Artificial Intelligence, ML, Machine Learning, Crypto Services, Blockchain Services, Data Services, Software Development, Mobile Application Development, Mobile App, E-commerce, Website Development, Social Media Marketing, Digital Marketing, Graphics Design, Resource & Hiring, Fintech, Gaming, Banking, Retail, Advertising, Media & Entertainment, Staffing, Outsourcing, Training, Permanent Placements" />
      </Helmet>
      <Navigation />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/gaming-solutions" element={<GamingSolutions />} />
        <Route path="/advertising-solutions" element={<AdvertisingSolutions />} />
        <Route path="/ecommerce-solutions" element={<EcommerceSolutions />} />
        <Route path="/general-staffing" element={<GeneralStaffingSolutions />} />
        <Route path="/outsourcing-services" element={<OutsourcingServices />} />
        <Route path="/hire-train-deploy" element={<HireTrainDeploy />} />
        <Route path="/software-development" element={<SoftwareDevelopment />} />
        <Route path="/ecommerce-application" element={<EcommerceApplicationServices />} />
        <Route path="/website-development" element={<WebsiteDevelopment />} />
        <Route path="/social-media-marketing" element={<SocialMediaMarketingServices />} />
        <Route path="/design-and-graphics" element={<DesignGraphicServices />} />
        <Route path="/resource-and-hiring" element={<ResourcingHiringServices />} />
        <Route path="/crypto-and-blockchain" element={<CryptoBlockchainServices />} />
        <Route path="/data-science-ai" element={<DataScienceAIServices />} />
        <Route path="/permanent-placements" element={<PermanentPlacements />} />
        <Route path="/fintech-solutions" element={<FintechSolutions />} />
        <Route path="/retail-solutions" element={<RetailSolutions />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/portfolio" element={<Portfolio />} />
      </Routes>
      <Footer />
    </div>
    </HelmetProvider>
  );
}

export default App;
