import React from "react";
// import { Helmet } from "react-helmet";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Hero from "./Hero/Hero";
import About from "./About/About";
import Services from "./Services/Services";
import News from "./News/News";

const Home = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>
          Best Mobile Application Development Company in India | Pockets Budget
          Software Development Company in India | BindassDeal
        </title>
        <meta
          name="description"
          content="BindassDeal is a leading best mobile application development company in India that specializes in using the latest technologies for businesses to develop innovative software solutions and apps."
        />
      </Helmet>
      <Hero />
      <About />
      <Services />
      <News />
    </HelmetProvider>
  );
};

export default Home;
