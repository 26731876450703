import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import classes from "./Footer.module.css";

const Footer = () => {
  const footerLinks = useSelector((state) => state.content.footerLinks);

  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        <div className={classes.footerContent}>
          <div className={classes.logoSocial}>
            <Link to="">
              <img
                src={require("../../../resources/image/footer-logo.png")}
                alt=""
                className={classes.footerLogo}
              />
            </Link>
            <p className={classes.companyDesc}>
              We can help you with the solutions you need to meet your business
              goals.
            </p>
            <div className={classes.socials}>
              <Link
                to="https://www.facebook.com/Bindass-Deal-102504822786603"
                target={"_blank"}
                className={classes.socialLink}
              >
                <img
                  src={require("../../../resources/image/facebook.png")}
                  alt="facebook"
                />
              </Link>
              <Link to="" className={classes.socialLink}>
                <img
                  src={require("../../../resources/image/twitter.png")}
                  alt="twitter"
                />
              </Link>
              <Link
                to="https://www.linkedin.com/company/90988250/admin/"
                target={"_blank"}
                className={classes.socialLink}
              >
                <img
                  src={require("../../../resources/image/linkedin.png")}
                  alt="linkedin"
                />
              </Link>
              <Link
                to="https://www.instagram.com/bindass_deal/"
                target={"_blank"}
                className={classes.socialLink}
              >
                <img
                  style={{
                    height: "38px",
                    width: "38px",
                    backgroundColor: "transparent",
                    borderRadius: "50px"
                  }}
                  src={require("../../../resources/image/instagram.png")}
                  alt="twitter"
                />
              </Link>
            </div>
          </div>
          <div className={classes.detailsLinks}>
            <ul className={classes.detailsLinksList}>
              {footerLinks.map((data, i) => {
                const { id, title, links } = data;
                return (
                  <li key={id} className={classes.detailsLinksListItem}>
                    <h5>{title}</h5>
                    <ul className={classes.linksList}>
                      {links.map((item, i) => {
                        return (
                          <li key={item.id} className={classes.linksListItem}>
                            <Link to={item.page} className={classes.link}>
                              {item.link}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className={classes.copyrightText}>
          <h5>
            All rights reserved@<span>BindassDeal.com</span>
          </h5>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
