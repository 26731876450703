import React from "react";
// import { Helmet } from "react-helmet";
import { Helmet, HelmetProvider } from "react-helmet-async";
import classes from "./WebsiteDevelopment.module.css";

const WebsiteDevelopment = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>
          Website Development company in Mumbai | CMS Development Service in
          Singapore | Content Management Services in Singapore
        </title>
        <meta
          name="description"
          content="When you are looking for the best website development company in Mumbai, look no further than Bindassdeal. Our experienced and dedicated team of web developers offers a wide range of services to meet your online business needs."
        />
      </Helmet>
      <section className={classes.hero}>
        <div className={classes.title}>
          <div className={classes.container}>
            <h5 className={classes.pageName}>Website Development</h5>
            {/* <h3 className={classes.headingTitle}>
        <span>Bindassdeal</span> Private Limited
      </h3> */}
            <p className={classes.headingDesc}>
              {/* At Bindassdeal Private Limited, we */}
              We offer website development services that help businesses of all
              sizes establish a strong online presence and connect with
              customers, partners, and other stakeholders through web-based
              platforms.
              {/* Our team of experienced website developers, designers, and project managers can deliver
              solutions that are tailored to your unique business needs, whether
              you require a new website, an update to an existing site, or
              integration with other systems. */}
            </p>
          </div>
        </div>
      </section>
      <section className={classes.about}>
        <div className={classes.container}>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1577100078279-b3445eae827c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Custom Website Development</h3>
              <p>
                We can develop custom websites that are tailored to your
                business needs, ensuring that your website is efficient,
                scalable, and user-friendly.
              </p>
            </div>
          </div>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1597534458220-9fb4969f2df5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Website Redesign</h3>
              <p>
                We can redesign your existing website to improve its appearance,
                usability, and functionality, ensuring that your website
                reflects your brand image and effectively communicates your
                message to your target audience.
              </p>
            </div>
          </div>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1599658880436-c61792e70672?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Content Management System (CMS) Development</h3>
              <p>
                We can develop CMS solutions that enable you to easily manage
                and update your website content, ensuring that your website
                remains up-to-date with the latest information and trends.
              </p>
            </div>
          </div>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1530435460869-d13625c69bbf?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Ecommerce Website Development</h3>
              <p>
                We can develop ecommerce websites that provide a seamless
                shopping experience for your customers, with intuitive
                navigation, user-friendly interfaces, and secure checkout
                processes.
              </p>
            </div>
          </div>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1584438784894-089d6a62b8fa?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Mobile-Optimized Website Development</h3>
              <p>
                We can develop websites that are optimized for mobile devices,
                ensuring that your customers can access your website from
                anywhere, at any time, and on any device.
              </p>
            </div>
          </div>
          <div className={classes.aboutDesc}>
            <div className={classes.aboutImg}>
              <img
                src="https://images.unsplash.com/photo-1511376777868-611b54f68947?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                alt="img"
              />
            </div>
            <div className={classes.aboutText}>
              <h3>Website Maintenance and Support</h3>
              <p>
                Our team can provide ongoing maintenance and support services,
                ensuring that your website continues to meet your business needs
                and remain up-to-date with the latest technology trends.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className={classes.summary}>
        <div className={classes.container}>
          <div className={classes.summaryText}>
            <p>
              At Bindassdeal Private Limited, we are committed to delivering
              website solutions that help our clients achieve their business
              goals.
              {/* Contact us today to learn more about how we can help you
              establish a strong online presence and connect with your target
              audience. */}
            </p>
          </div>
        </div>
      </section>
    </HelmetProvider>
  );
};

export default WebsiteDevelopment;

/*

Website Development

At Bindassdeal Private Limited, we offer website development services that help businesses of all sizes establish a strong online presence and connect with customers, partners, and other stakeholders through web-based platforms. Our team of experienced website developers, designers, and project managers can deliver solutions that are tailored to your unique business needs, whether you require a new website, an update to an existing site, or integration with other systems.
Our website development services include:
-Custom Website Development: We can develop custom websites that are tailored to your business needs, ensuring that your website is efficient, scalable, and user-friendly.
-Website Redesign: We can redesign your existing website to improve its appearance, usability, and functionality, ensuring that your website reflects your brand image and effectively communicates your message to your target audience.
-Content Management System (CMS) Development: We can develop CMS solutions that enable you to easily manage and update your website content, ensuring that your website remains up-to-date with the latest information and trends.
-Ecommerce Website Development: We can develop ecommerce websites that provide a seamless shopping experience for your customers, with intuitive navigation, user-friendly interfaces, and secure checkout processes.
-Mobile-Optimized Website Development: We can develop websites that are optimized for mobile devices, ensuring that your customers can access your website from anywhere, at any time, and on any device.
-Website Maintenance and Support: Our team can provide ongoing maintenance and support services, ensuring that your website continues to meet your business needs and remain up-to-date with the latest technology trends.
At Bindassdeal Private Limited, we are committed to delivering website solutions that help our clients achieve their business goals. Contact us today to learn more about how we can help you establish a strong online presence and connect with your target audience.

*/
