import React, { useState } from "react";
import { Link } from "react-router-dom";
// import { Helmet } from "react-helmet";
import { Helmet, HelmetProvider } from "react-helmet-async";

import classes from "./ContactUs.module.css";

// prettier-ignore
const ContactUs = () => {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [comment, setComment] = useState('');

    const submitHandler = (e) => {
        e.preventDefault();

        console.log({ fName: firstName, lName: lastName, email: email, phone: phoneNumber, comment: comment });
        
        setFirstName('');
        setLastName('');
        setEmail('');
        setPhoneNumber('');
        setComment('');
    }

  return (
    <HelmetProvider>
    <Helmet>
        <title>Contact Us | BindassDeal</title>
        <meta
          name="description"
          content="If you need a reliable website development company or have any questions, don't hesitate to contact Bindassdeal. We will be happy to help."
        />
      </Helmet>
      <section className={classes.hero}>
        <div className={classes.title}>
          <div className={classes.container}>
            <h5 className={classes.pageName}>Contact us</h5>
            <p className={classes.headingDesc}>
              We would like to hear from you
            </p>
          </div>
        </div>
      </section>
      <section className={classes.form}>
        <div className={classes.container}>
          <h4 className={classes.contactTitle}>Tell us how to contact you and we'll get in touch.</h4>
          <form onSubmit={submitHandler}>
            <div className={classes.names}>
                <div><label htmlFor="firstName"><span>First name &#x26B9;</span><input type="text" placeholder="John" id="firstName" value={firstName} onChange={(e) => setFirstName(e.target.value)} required/></label></div>
                <div><label htmlFor="lastName"><span>Last name &#x26B9;</span><input type="text" placeholder="Doe" id="lastName" value={lastName} onChange={(e) => setLastName(e.target.value)} required/></label></div>
            </div>
            <div className={classes.contacts}>
                <div><label htmlFor="email"><span>Email &#x26B9;</span><input type="email" placeholder="john.doe@gmail.com" id="email" value={email} onChange={(e) => setEmail(e.target.value)} required/></label></div>
                <div><label htmlFor="phoneNumber"><span>Phone</span><input type="tel" placeholder="+91-9812345678" id="phoneNumber"  maxLength={14} value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} required/></label></div>
            </div>
            <div className={classes.comments}>
                <div><label htmlFor="comment"><span>Comments &#x26B9;</span><textarea rows={6} placeholder="Enter your message..." id="comment" value={comment} onChange={(e) => setComment(e.target.value)} required/></label></div>
            </div>
            <div className={classes.submitBtn}>
                <button type="submit">Submit</button>
            </div>
          </form>
        </div>
      </section>
      <section className={classes.summary}>
        <div className={classes.container}>
          <div className={classes.summaryText}>
            <p>
              For general technical issues or questions, please view our FAQ
              page. For other enquiries, please contact us at{" "}
              <span className={classes.emailLink}>
                <Link to="contact.bindassdeal@gmail.com">
                  contact.bindassdeal@gmail.com
                </Link>
              </span>
            </p>
          </div>
        </div>
      </section>
    </HelmetProvider>
  );
};

export default ContactUs;

/* <div className={classes.aboutDesc}>
<div className={classes.aboutImg}>
    <img
    src="https://images.unsplash.com/photo-1556742077-0a6b6a4a4ac4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
    alt="img"
    />
</div>
<div className={classes.aboutText}>
    <h3>Point of Sale (POS) System</h3>
    <p>
    We can develop a POS system that is tailored to your business
    needs and helps you manage sales, inventory, and customer data.
    Our team can create a user-friendly interface, intuitive
    navigation, and streamlined checkout process that makes it easy
    for your employees to manage transactions.
    </p>
</div>
</div> */
